import moment from 'moment-timezone';
import dayjs from 'dayjs';
/**
 *
 * @param {array} a
 * @param {array} b
 * @param {*} orderBy
 * @returns comparition of two array
 * used for table sorting dynamic
 */
export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

/**
 *
 * @param {*} order
 * @param {*} orderBy
 * @returns sorted list of array by give order
 */
export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 *
 * @param {array} array
 * @param {*} comparator
 * @returns sort given array through comparator
 */
export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {

    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function getFilteredBy(rows, filterBy) {
  const filterKeys = Object.keys(filterBy);

  let frows = rows.filter((item) => {
    // validates all filter criteria
    return filterKeys.every((key) => {
      if (filterBy[key] !== '') {
        if (key === 'name') {
          return String(item[key])
            .toLowerCase()
            .includes(String(filterBy[key]).toLowerCase());
        } else if (
          [
            'dateOfRequest',
            'created_time',
            'updatedAt',
            'lastupdated',
            'lastupdatedtime',
            'createdOn',
          ].includes(key)
        ) {
          if (filterBy[key].start !== '' && filterBy[key].end !== '') {
            const date = moment(item[key]).format('YYYY-MM-DD');
            const start = moment(filterBy[key].start).format('YYYY-MM-DD');
            const end = moment(filterBy[key].end).format('YYYY-MM-DD');
            if (filterBy[key].start === filterBy[key].end) {
              return date > start && date < end;
            } else {
              return date >= start && date <= end;
            }
          } else {
            return true;
          }
        } else {
          return (
            String(filterBy[key]).toLowerCase() ===
            String(item[key]).toLowerCase()
          );
        }
      } else {
        return true;
      }
    });
  });

  return frows;
}

/**
 *
 * @returns Random Number
 */
export function getRandomNumber() {
  const min = 1;
  const max = 100;
  const rand = min + Math.random() * (max - min);
  return rand;
}

/**
 *
 * @param {string} str
 * @returns unique string id (prefix given str)
 */
export function getuniqueId(str) {
  var uniq = (str ? str : '') + new Date().getTime();
  return uniq;
}

/**
 *
 * @param {String} url
 * @param {String} key
 * @returns value of parameter from url
 */
export function getParamsFromUrl(url, key) {
  const queryString = url;
  const urlParams = new URLSearchParams(queryString);
  let val = urlParams.get(key);
  return val ? val : false;
}

/**
 * Generate password for new registred users
 * @returns password string
 */
export function generateP() {
  var pass = '';
  var str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$';
  for (var i = 1; i <= 10; i++) {
    var char = Math.floor(Math.random() * str.length + 1);

    pass += str.charAt(char);
  }

  return pass;
}

/**
 *
 * @param {datetime} startTime
 * @param {datetime} endTime
 * @returns duration bitween two date times
 */
export function getDuration(startTime, endTime) {
  if (startTime && endTime) {
    // start time and end time
    var startTimeA = moment(startTime, 'HH:mm:ss a');
    //console.log(startTime);
    var endTimeA;
    if (startTime.slice(-2) === 'AM') {
      endTimeA = moment(endTime, 'HH:mm:ss a');
    } else {
      endTimeA = moment(endTime, 'HH:mm:ss a').add(1, 'd');
    }

    //console.log(startTime.slice(-2));

    // calculate total duration
    var duration = moment.duration(endTimeA.diff(startTimeA));

    // duration in hours
    var hours = parseInt(duration.asHours());

    // duration in minutes
    var minutes = parseInt(duration.asMinutes()) % 60;

    return hours + ':' + minutes;
  }
  return '';
}

export function getDurationDiff(startTime, endTime) {
  if (startTime && endTime) {
    // start time and end time
    var startTimeA = moment(startTime, 'HH:mm:ss a');
    var endTimeA;
    if (startTime.slice(-2) === 'AM') {
      endTimeA = moment(endTime, 'HH:mm:ss a');
    } else {
      endTimeA = moment(endTime, 'HH:mm:ss a').add(1, 'd');
    }
    // calculate total duration
    var duration = moment.duration(endTimeA.diff(startTimeA));

    // duration in hours
    var hours = parseInt(duration.asHours());

    // duration in minutes
    //var minutes = parseInt(duration.asMinutes()) % 60;
    if (hours <= 20) {
      return true;
    } else {
      return false;
    }
  }
  return '';
}

export function minTwoDigits(n) {
  if (n >= 0) return (n < 10 ? '0' : '') + n;
  else return n;
}

/**
 *
 * @param {datetime} startTime
 * @returns formated time
 */
export function getTimeFormat(startTime) {
  const today = moment.utc();
  const [hour, minute] = startTime.split(':');
  let sdate = moment(today).set('hours', hour).set('minute', minute);

  return moment(sdate).format('hh:mm A');
}
export function splitTimeValues(time) {
  if (time) {
    const [hourminute, p] = time.split(' ');
    const [hour, minute] = hourminute.split(':');
    return { hour, minute, p };
  }
  return {};
}
export function groupByKey(array, key) {
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash;
    return Object.assign(hash, {
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    });
  }, {});
}

export function groupByKeyDate(array, key) {
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash;
    return Object.assign(hash, {
      [moment(Number(obj[key])).format('MMMM D, YYYY')]: (
        hash[moment(Number(obj[key])).format('MMMM D, YYYY')] || []
      ).concat(obj),
    });
  }, {});
}

export function chagedtime(time) {
  const fireBaseTime = moment(
    time?.seconds * 1000 + time?.nanoseconds / 1000000
  );
  //console.log(fireBaseTime);
  const timeno = moment(fireBaseTime);
  const nowtime = moment.tz(timeno, 'America/New_York').format();
  // const nowtimen = moment(nowtime);
  //console.log(nowtime);
  var duration = ` ${moment(nowtime).format('MMM DD, YY')} ${'\n'} ${moment(
    nowtime
  ).format('hh:mm:ss')}`;

  //var formatted = duration.format("hh:mm:ss");
  return duration;
}
export function getFormatedDate(timestamp) {
  return timestamp
    ? moment(Number(timestamp)).format('MMMM D, YYYY')
    : timestamp;
}
export function FormatedDates(timestamp) {
  return timestamp
    ? moment(Number(timestamp)).format('MMM D, YYYY')
    : timestamp;
}
export function getFormatedTime(timestamp) {
  return timestamp ? moment(Number(timestamp)).format('hh:mm:ss A') : timestamp;
}
/**
 *
 * @param {array} myArray
 * @returns unique array
 */
export function getUniqueArray(myArray) {
  return myArray.filter((v, i, a) => a.indexOf(v) === i);
}


export function formatDate(timestamp) {
  const date = timestamp ? new Date(timestamp.seconds * 1000) : null;
  return date ? moment(date).format("MMM DD, YYYY") : "---";
};

export function convertFirestoreTimestampToDate(timestamp) {
  if (!timestamp) {
    return null;
  }
  return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
};

export function datetimest() {
  var d = new Date();
  var c = d.toLocaleString('en-US', { timeZone: 'America/New_York' });

  var sensibleFormat = new Date(c);
  var milliseconds = sensibleFormat.getTime();

  return milliseconds;
}

export function cleanMessage(message) {
  return message.replace(/\s+/g, ' ').trim();
};

export function generateTimes() {
  const times = [];

  for (let hour = 8; hour < 24; hour++) {
      const suffix = hour < 12 ? 'am' : 'pm';
      const hourIn12 = hour % 12 === 0 ? 12 : hour % 12;
      const formattedHour = hourIn12.toString().padStart(2, '0');
      times.push(`${formattedHour}:00 ${suffix}`);
      times.push(`${formattedHour}:30 ${suffix}`);
  }

  for (let hour = 0; hour < 8; hour++) {
      const suffix = 'am';
      const hourIn12 = hour % 12 === 0 ? 12 : hour % 12;
      const formattedHour = hourIn12.toString().padStart(2, '0');
      times.push(`${formattedHour}:00 ${suffix}`);
      times.push(`${formattedHour}:30 ${suffix}`);
  }

  return times;
}

export function timeToMinutes (timeStr) {
  if (!timeStr || typeof timeStr !== 'string') {
    return null;
}
  const [time, period] = timeStr.split(' ');
  const [hours, minutes] = time.split(':').map(Number);
  const isPM = period === 'pm' && hours !== 12;
  const isMidnight = period === 'am' && hours === 12;
  const totalMinutes = (isMidnight ? 0 : (isPM ? (hours + 12) : hours)) * 60 + minutes;
  return totalMinutes;
};

// Helper function to get the ordinal suffix for a date
function getOrdinalSuffix(day) {
  if (day > 3 && day < 21) return "th"; // Handles 11th, 12th, 13th, etc.
  switch (day % 10) {
    case 1: return "st";
    case 2: return "nd";
    case 3: return "rd";
    default: return "th";
  }
}

const schedulingOptions = [
  "Daily",
];

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export function generateOptionsList(selectedDate) {
  if (!selectedDate) {
    return [];
  }

  const dayIndex = selectedDate.$W;
  const dayOfWeek = daysOfWeek[dayIndex];
  const dayOfMonth = selectedDate.$D;

  // Determine the week of the month
  const weekOfMonth = Math.ceil(dayOfMonth / 7);
  const weekNames = ["First", "Second", "Third", "Fourth", "Fifth"];
  const weekName = weekNames[weekOfMonth - 1] || "Fifth";

  const optionsList = [];

  optionsList.push({
    label: `Every ${dayOfWeek}`,
    value: `every_${dayOfWeek.toLowerCase()}`,
  });

  optionsList.push({
    label: `Every Other ${dayOfWeek}`,
    value: `every_other_${dayOfWeek.toLowerCase()}`,
  });

  optionsList.push({
    label: `Every Third ${dayOfWeek}`,
    value: `every_third_${dayOfWeek.toLowerCase()}`,
  });

  optionsList.push({
    label: `Every Fourth ${dayOfWeek}`,
    value: `every_fourth_${dayOfWeek.toLowerCase()}`,
  });

  optionsList.push({
    label: `The ${dayOfMonth}${getOrdinalSuffix(dayOfMonth)} of Every Month`,
    value: `the_${dayOfMonth}_of_every_month`,
  });

  optionsList.push({
    label: `The ${weekName} ${dayOfWeek} of Every Month`,
    value: `the_${weekName.toLowerCase()}_${dayOfWeek.toLowerCase()}_of_every_month`,
  });

  schedulingOptions.forEach(option => {
    optionsList.push({
      label: option,
      value: option.toLowerCase().replace(/ /g, "_"),
    });
  });

  return optionsList;
}

export const numberOptions = Array.from({ length: 30 }, (_, i) => ({
  label: (i + 1).toString(),
  value: i + 1,
}));

export function calculateDurationInMinutes(startTime, endTime) {
  const start = moment(startTime, "hh:mm A");
  const end = moment(endTime, "hh:mm A"); 

  return end.diff(start, 'minutes');
}

export function generateAppointmentTypeId () {
  return Math.floor(Math.random() * 100000000);
};


export function generateUniqueId () {
  return Math.floor(Math.random() * 10000000000);
};

// export function formatSelectedDateTime(selectedDate, startTime) {
//   const momentSelectedDate = moment(selectedDate).startOf('day');
//   const momentStartTime = moment(startTime, 'hh:mm A');

//   const combinedDateTime = momentSelectedDate.set({
//     hour: momentStartTime.hour(),
//     minute: momentStartTime.minute(),
//     second: 0,
//   });

//   return combinedDateTime.format('YYYY-MM-DDTHH:mm:ssZ');
// }

function getTimezoneOffset(timeZone) {
  if(!timeZone){
    return null;
  }
  const str = new Date().toLocaleString('en', { timeZone, timeZoneName: 'longOffset' });
  const [, h, m] = str.match(/([+-]\d+):(\d+)$/) || [, '+00', '00'];
  return h * 60 + (h > 0 ? +m : -m);
}

const formatOffset = (offsetMinutes) => {
  const sign = offsetMinutes >= 0 ? "+" : "-";
  const absOffset = Math.abs(offsetMinutes);
  const hours = Math.floor(absOffset / 60).toString().padStart(2, '0');
  const minutes = (absOffset % 60).toString().padStart(2, '0');
  return `${sign}${hours}:${minutes}`;
};


export function formatSelectedDateTime(selectedDate, startTime, timezone) {
  if (!selectedDate || !startTime) {
      return null;
  }

  let momentSelectedDate;

  if (dayjs.isDayjs(selectedDate)) {
    momentSelectedDate = moment(selectedDate.toDate());
  } else if (selectedDate instanceof Date) {
    momentSelectedDate = moment(selectedDate);
  } else {
    momentSelectedDate = moment(selectedDate, ['MM/DD/YYYY', 'MM-DD-YYYY', 'DD/MM/YYYY', 'DD-MM-YYYY'], true);
    if (!momentSelectedDate.isValid()) {
      throw new Error('Invalid date format. Please use DD/MM/YYYY, DD-MM-YYYY, MM-DD-YYYY, or MM/DD/YYYY.');
    }
  }

  const momentStartTime = moment(startTime, 'hh:mm A');


  if (!momentStartTime.isValid()) {
    throw new Error('Invalid time format. Please use hh:mm AM/PM format.');
  }

  const combinedDateTime = momentSelectedDate.set({
    hour: momentStartTime.hour(),
    minute: momentStartTime.minute(),
    second: 0,
  });

  const timeZoneOffset = getTimezoneOffset(timezone);
  const formatedOffset = formatOffset(timeZoneOffset);
  return `${combinedDateTime.format('YYYY-MM-DDTHH:mm:ss')}${formatedOffset}`;
}

export function ValidDate(dateString) {
  if (!dateString) {
    return null;
  }
  const regex = /^(0[1-9]|[12][0-9]|3[01])[\/-](0[1-9]|1[0-2])[\/-](\d{4})$/;

  if (!dateString.match(regex)) return false;

  const [day, month, year] = dateString.split(/[\/-]/).map(Number);

  const date = new Date(year, month - 1, day);

  const isValidDate =
      date.getFullYear() === year &&
      date.getMonth() === month - 1 &&
      date.getDate() === day;

  return isValidDate;
}
// export function ValidTime (timeString) {
//   const regex = /^(0?[1-9]|1[0-2]):([0-5][0-9]\s?[APap][mM])$/;

//   return regex.test(timeString);
// };
export function formatTime(timeString) {
  if (!timeString) return "Invalid time";
  const trimmedTime = timeString.trim();

  const isCorrectFormat = moment(trimmedTime, "hh:mm a", true).isValid();
  if (isCorrectFormat) {
    return trimmedTime.toLowerCase();
  }

  const parsedTime = moment(trimmedTime, ["h:mm a", "h a", "hhmma", "hhmm a", "hmm a", "hmm"], true);

  if (parsedTime.isValid()) {
    return parsedTime.format("hh:mm a").toLowerCase();
  } else {
    const fallbackTime = moment(trimmedTime, ["h", "hh", "hmm", "hhmm"], true);
    if (fallbackTime.isValid()) {
      return fallbackTime.format("hh:mm a").toLowerCase();
    }
  }
  return "Invalid time";
}
export function ValidTime(timeString) {
  const regex = /^(0?[1-9]|1[0-2]):([0-5][0-9]\s?[APap][mM])$|^(0?[1-9]|1[0-2]|[0-9])\s?([APap][mM])?$|^(\d{1,4})([APap][mM])?$/;

  if (!regex.test(timeString)) {
      return false;
  }

  let hours, minutes = 0, period = '';

  if (timeString.includes(':')) {
      const [hourPart, minutePart] = timeString.split(':');
      hours = parseInt(hourPart, 10);
      minutes = parseInt(minutePart.slice(0, 2), 10);
      period = minutePart.slice(2).trim();
  } else if (timeString.length <= 4) {
      const match = timeString.match(/^(\d{1,2})([APap][mM])?$/);
      if (match) {
          hours = parseInt(match[1], 10);
          if (match[2]) period = match[2].toLowerCase();
          if (timeString.length === 3) {
              minutes = parseInt(timeString.slice(1), 10);
          } else if (timeString.length === 4) {
              minutes = parseInt(timeString.slice(2), 10);
          }
      }
  } else {
      const match = timeString.match(/^(\d{1,2})([APap][mM])?$/);
      if (match) {
          hours = parseInt(match[1], 10);
          if (match[2]) period = match[2].toLowerCase();
      }
  }

  if (period) {
      if (period === 'pm' && hours < 12) {
          hours += 12;
      } else if (period === 'am' && hours === 12) {
          hours = 0;
      }
  }

  if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
      return false;
  }

  return true;
}
export function getUpcomingDates(startDate, frequency, numberOfTimes) {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const results = [];
  const start = new Date(startDate);

  if (isNaN(start.getTime())) {
    throw new Error('Invalid start date format. Please use a valid date format (e.g., "October 24, 2024").');
  }

  const frequencyMap = {
    every: 1,
    every_other: 2,
    daily: 1,
    every_third: 3,
    every_fourth: 4,
  };

  const frequencyParts = frequency.split('_');
  const baseFrequency = frequencyParts.slice(0, -1).join('_'); // Handle cases like "every_other", "every_third"
  const dayName = frequencyParts[frequencyParts.length - 1]; // e.g., "monday"
  const frequencyInterval = frequencyMap[baseFrequency] || 1; // Default to 1 if not found

  let dayIndex = -1;
  if (dayName) {
    dayIndex = daysOfWeek.indexOf(dayName.charAt(0).toUpperCase() + dayName.slice(1)); // Convert to title case
  }

  // Case 1: Specific day of the month (e.g., "the 22nd of every month")
  if (frequencyParts.length === 5 && frequencyParts[0] === 'the') {
    const dayOfMonth = parseInt(frequencyParts[1]);
    if (dayOfMonth < 1 || dayOfMonth > 31) {
      throw new Error('Invalid day of month in frequency.');
    }

    let currentYear = start.getFullYear();
    let currentMonth = start.getMonth();

    for (let i = 0; results.length < numberOfTimes; i++) {
      let targetDate = new Date(currentYear, currentMonth, dayOfMonth);

      // Ensure the day is valid within the current month
      if (targetDate.getMonth() !== currentMonth) {
        targetDate = new Date(currentYear, currentMonth + 1, 0); // Adjust if the day is out of range in the month
      }

      if (targetDate >= start) {
        results.push(formatLeapYear(targetDate));
      }

      currentMonth += 1;
      if (currentMonth > 11) {
        currentMonth = 0;
        currentYear += 1;
      }
    }
  }

  // Case 2: Week-specific (e.g., "the third Saturday of every month")
  else if (frequencyParts.length === 6 && frequencyParts[0] === 'the') {
    const weekMap = { first: 1, second: 2, third: 3, fourth: 4, fifth: 5 };
    const targetWeek = weekMap[frequencyParts[1].toLowerCase()];
    const targetDay = frequencyParts[2].toLowerCase(); // e.g., "saturday"
    const targetDayIndex = daysOfWeek.indexOf(targetDay.charAt(0).toUpperCase() + targetDay.slice(1));

    let currentYear = start.getFullYear();
    let currentMonth = start.getMonth();

    for (let i = 0; results.length < numberOfTimes; i++) {
      const firstOfMonth = new Date(currentYear, currentMonth, 1);
      const firstDayOfWeek = firstOfMonth.getDay();
      let dayOfMonth = 1 + ((targetDayIndex - firstDayOfWeek + 7) % 7) + (targetWeek - 1) * 7;

      const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
      if (dayOfMonth > daysInMonth) {
        dayOfMonth -= 7; // Adjust if the target week exceeds the days in the month
      }

      const targetDate = new Date(currentYear, currentMonth, dayOfMonth);
      if (targetDate >= start) {
        results.push(formatLeapYear(targetDate));
      }

      currentMonth += 1;
      if (currentMonth > 11) {
        currentMonth = 0;
        currentYear += 1;
      }
    }
  }

  // Case 3: Daily frequency
  else if (frequency === 'daily') {
    let currentDate = new Date(start);
    for (let i = 0; results.length < numberOfTimes; i++) {
      results.push(formatLeapYear(currentDate));
      currentDate.setDate(currentDate.getDate() + 1); // Increment by 1 day
    }
  }

  // Case 4: Regular frequency-based dates (e.g., "every Wednesday")
  else if (dayIndex !== -1) {
    let currentDate = new Date(start);
    currentDate.setDate(currentDate.getDate() + ((dayIndex + 7 - currentDate.getDay()) % 7)); // Find the first occurrence of the specified day

    // Add the next occurrences
    for (let i = 0; results.length < numberOfTimes; i++) {
      results.push(formatLeapYear(currentDate));
      currentDate.setDate(currentDate.getDate() + 7 * frequencyInterval); // Increment by frequency interval (in weeks)
    }
  }

  // Case 5: Weekly interval dates (e.g., "every_other_week", "every_third_week")
  else {
    let currentDate = new Date(start);

    // Add the next occurrences based on weekly intervals
    for (let i = 1; results.length < numberOfTimes; i++) {
      results.push(formatLeapYear(currentDate));
      currentDate.setDate(currentDate.getDate() + 7 * frequencyInterval); // Add the interval (in weeks)
    }
  }

  return results;
}

function formatLeapYear(date) {
  if (isNaN(date.getTime())) {
    return 'Invalid Date';
  }
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return new Date(date).toLocaleDateString('en-US', options);
}

export function filterAppointmentsOnSameDate(appointments, selectedClientId, targetDate) {

  if (!appointments || !appointments.appointments) {
    return [];
  }

  const targetDateFormatted = dayjs(targetDate).startOf('day');
  const filteredAppointments = appointments.appointments.filter(appointment => {
    const appointmentDateFormatted = dayjs(appointment.date, "MMMM D, YYYY").startOf('day');
    return appointment.clientId === selectedClientId && appointmentDateFormatted.isSame(targetDateFormatted, 'day');
  });

  if (filteredAppointments.length > 0) {
    return filteredAppointments.map(appointment => ({
      id: appointment.id,
      date: appointment.date,
      time: appointment.time,
    }));
  }

  return [];
}