import React, { useState, useContext } from "react";
import AuthContext from "../../hooks/authContext";
import { Container } from "@material-ui/core";
import PageTitle from "../../components/pageTitle/PageTitle";
import { useEffect } from "react";
import confirm from "../../components/DialogComponent/confirm";
import {
  getEmployee,
  setUserInformation,
} from "../../services/apiservices/employee";
import { TextField, Button, Grid } from "@material-ui/core";
import { forgotPasswordAdmin } from "../../services/firebase";

import { useNavigate } from "react-router";
const ProfileContainer = (props) => {
  const auth = useContext(AuthContext);
  const profile = auth?.userProfile;
  const [name, setName] = useState();
  const [lname, setLname] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [username, setUsername] = useState();
  const [employeeData, setEmployeeData] = useState(false);
  const history = useNavigate();
  useEffect(() => {
    getEmployee(
      profile.userId,
      (res) => {
        if (res.status === true) {
          setEmployeeData(res?.data);
        }
      },
      (error) => console.log
    );
  }, [profile.userId]);
  useEffect(() => {
    setName(employeeData.firstName);
    setLname(employeeData.lastName);
    setEmail(employeeData.email);
    setPhone(employeeData.phoneNumber);
    setUsername(employeeData.userName);
  }, [employeeData]);
  const doSaveData = () => {
    let data = {
      firstName: name,
      lastName: lname,
      email: email,
      phoneNumber: phone,
    };

    if (data.firstName !== "" && data.lastName !== "" && data.email !== "") {
      setUserInformation(employeeData.id, data).then(() => {
        return alert("User Updated!");
      });
    } else alert("Fields not be Empty!");
  };

  const ResetPasswordLink = async () => {
    await forgotPasswordAdmin(
      email,
      phone,
      () => {
        alert("Reset Password Link sent to your Email...");
      }
    );
  };
  const onResetPassword = async () => {
    await confirm(
      "Are you Sure you want to send reset Password Link to your email?"
    ).then(
      () => {
        ResetPasswordLink();
      },
      () => {
        console.log("cancel!");
      }
    );
  };
  return (
    <>
      <Container>
        <PageTitle
          pageTitle="Profile"
          showBreadcrumb={[{ label: "Home", to: "/" }]}
        />

        <div className={`sectionBox`}>
          <div className="profileDetails">
            <div className="stepSectionContent pl-15">
              <h6 className="stepSectionTitle profilet d-flex flex-space-between flex-center">
                <div>Personal Information</div>
                <div style={{float:'right'}}><Button
                    variant="contained"
                    className="flatbutton button-danger"
                    onClick={onResetPassword}
                  >
                    Reset password
                  </Button></div>
              </h6>
              <Grid item sm={6} md={6}>
                <div className="formFieldwrap mb-40">
                  <TextField
                    fullWidth={true}
                    size="small"
                    name="name"
                    id="name"
                    label="First Name"
                    variant="outlined"
                    focused
                    onChange={(e) => setName(e.target.value)}
                    inputProps={{ maxLength: 25 }}
                    value={name}
                  />
                </div>
                <div className="formFieldwrap mb-40">
                  <TextField
                    fullWidth={true}
                    size="small"
                    name="lname"
                    id="lname"
                    label="Last Name"
                    variant="outlined"
                    focused
                    onChange={(e) => setLname(e.target.value)}
                    inputProps={{ maxLength: 25 }}
                    value={lname}
                  />
                </div>
                <div className="formFieldwrap mb-40">
                  <TextField
                    fullWidth={true}
                    size="small"
                    name="email"
                    id="email"
                    label="Email"
                    variant="outlined"
                    aria-readonly
                    focused
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </div>
                <div className="formFieldwrap mb-40">
                  <TextField
                    fullWidth={true}
                    size="small"
                    name="mobileNumber"
                    id="mobileNumber"
                    label="Mobile Number"
                    variant="outlined"
                    focused
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                  />
                </div>
                <div className="formFieldwrap mb-40">
                  <TextField
                    fullWidth={true}
                    size="small"
                    name="username"
                    id="username"
                    label="UserName"
                    variant="outlined"
                    focused
                    aria-readonly
                    value={username}
                  />
                </div>
                <div className="padbtbut">
                  <Button
                    variant="contained"
                    color="primary"
                    className="themebutton flatbutton mr-12"
                    onClick={doSaveData}
                  >
                    Save
                  </Button>

                  <Button
                    variant="contained"
                    className="themebutton flatbutton "
                    onClick={() => {
                      history("/dashboard");
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Grid>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default ProfileContainer;
