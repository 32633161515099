import { collection, getDocs, getDoc, addDoc, doc, updateDoc, deleteField, deleteDoc, setDoc, limit, orderBy, query, Timestamp } from 'firebase/firestore';
import { firebase} from '../firebase-config';
import { BaseUrl, COLLECTIONS } from '../config';
import axiosInstance from '../axios';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import moment from 'moment';

const auth = getAuth();


/**
 * Get Document list from client collection
 * @param {*} formData : can be use for filter in future
 * @param {*} onSuccess
 * @param {*} onError
 */
export const getClientsList = async (formData, onSuccess, onError) => {
  
  try {
    const colRef = collection(firebase, COLLECTIONS.CLIENT);

    const querySnapshot = await getDocs(colRef);

    const results = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    if (onSuccess) {
      onSuccess(results);
    }
  } catch (error) {
    console.error('Error getting documents:', error);
    if (onError) {
      onError(error);
    }
  }
};
/**
 * Get Document list from client collection
 * @param {*} formData : can be use for array ids
 * @param {*} onSuccess
 * @param {*} onError
 */
export const getClientsListbasedonIds = async (formData, onSuccess, onError) => {
  try {
    const results = await Promise.all(
      formData.map(async (id) => {
        const clientDoc = doc(collection(firebase, COLLECTIONS.CLIENT), id);
        const docSnapshot = await getDoc(clientDoc);
        
        if (docSnapshot.exists()) {
          return { id: docSnapshot.id, ...docSnapshot.data() };
        } else {
          console.warn(`Document with ID ${id} does not exist.`);
          return null;
        }
      })
    );

    const filteredResults = results.filter(item => item !== null);

    if (onSuccess) {
      onSuccess(filteredResults);
    }
  } catch (error) {
    console.error('Error getting documents:', error);
    if (onError) {
      onError(error);
    }
  }
};
/**
 * Get details of perticular client
 * @param {*} id : id of client want to get data of
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getClient(id, onSuccess, onError) {
  try {
    const docRef = doc(firebase, COLLECTIONS.CLIENT, id);
    const docSnapshot = await getDoc(docRef);

    if (!docSnapshot.exists()) {
      onError && onError({ status: false, error: 'Client not found' });
      return;
    }

    const data = docSnapshot.data();
    onSuccess && onSuccess({ status: true, data: { client_id: docSnapshot.id, ...data } });
  } catch (error) {
    onError && onError({ status: false, error });
  }
}

/**
 * Create a new client
 * @param {*} formData Data to save in firestore
 * @param {*} acuityClientData Data to save in acuity data
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function addClient(formData, acuityClientData, onSuccess, onError) {
  try {
    const resultNumberQuery = query(
      collection(firebase, COLLECTIONS.CLIENT),
      orderBy('clientNumber', 'desc'),
      limit(1)
    );
    const resultNumber = await getDocs(resultNumberQuery);

    let newClientNumber = 101;

    resultNumber.forEach((doc) => {
      const apptData = doc.data();
      newClientNumber = apptData.clientNumber + 1;
    });

    const currentUser = auth.currentUser;
    const currentUserId = currentUser ? currentUser.uid : null;

    const newClientData = {
      ...formData,
      createdBy: currentUserId,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
      clientNumber: newClientNumber,
    };

    const docRef = await addDoc(collection(firebase, COLLECTIONS.CLIENT), newClientData);

    const clientRes = await axiosInstance.post(`${BaseUrl}createClientAcuity`, {
      acuityClientData,
    });

    console.log('client acuity create', clientRes);

    const responseItem = { ...newClientData, id: docRef.id };
    onSuccess && onSuccess({ status: true, data: responseItem });

  } catch (error) {
    console.log(`ERROR: ${error.message || error}`);
    onError && onError({ status: false, error: error.message || error });
  }
}

/**
 *  Update Data of perticular client
 * @param {*} id : id of client want to update
 * @param {*} formData  : only pass data should be updated
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function updateClient(id, formData, acuityClientData, onSuccess, onError) {
  try {
    const currentUser = auth.currentUser;
    const currentUserId = currentUser ? currentUser.uid : null;

    const clientDoc = doc(firebase, COLLECTIONS.CLIENT, id);

    await setDoc(
      clientDoc,
      {
        ...formData,
        updatedAt: Timestamp.now(),
        updatedBy: currentUserId,
      },
      { merge: true }
    );

    const clientUpdateResponse = await axiosInstance.post(`${BaseUrl}updateClientAcuity`, {
      acuityClientData,
    });
    
    console.log('Client Acuity updated:', clientUpdateResponse);

    onSuccess && onSuccess({ status: true, data: formData });

  } catch (error) {
    console.error('Error updating client:', error);

    if (error.code === 'not-found') {
      onError && onError({ status: false, message: 'Client Not Found' });
    } else {
      onError && onError({ status: false, error: error.message || error });
    }
  }
}

/**
 * Remove client data from document
 * @param {*} id : id of client
 * @param {*} onSuccess
 * @param {*} onError
 */

export async function deleteClient(id, acuityClientDatadele, onSuccess, onError) {
  const documentRef = doc(firebase, COLLECTIONS.CLIENT, id);

  try {
    await deleteDoc(documentRef);
    await axiosInstance.post(`${BaseUrl}deleteClientAcuity`, { acuityClientDatadele });

    onSuccess && onSuccess({ status: true, message: 'Delete successful' });
  } catch (error) {
    console.error('Error deleting client:', error);
    onError && onError({ status: false, error: error.message || 'An error occurred' });
  }
}

/**
 * Remove client data from document
 * @param {*} id : id of client
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function deleteClientTaskGroup(id, taskItem) {
  try {
    const updates = {};
    const shiftName = taskItem?.shift.shiftId;

    updates[`shiftData.${shiftName}`] = deleteField();

    // Optionally, update the timestamp
    // updates['updatedAt'] = serverTimestamp(); // Uncomment if you want to update the timestamp

    const docRef = doc(firebase, COLLECTIONS.CLIENT, id); 
    await updateDoc(docRef, updates); 

    return true;
  } catch (error) {
    console.error('Error deleting client task group:', error); 
    return null;
  }
}

export async function deleteClientShift(id, taskItem) {
  try {
    const updates = {};
    const shiftName = taskItem?.shiftId;

    updates[`shiftData.${shiftName}`] = deleteField();

    // Optionally, update the timestamp
    // updates['updatedAt'] = serverTimestamp(); // Uncomment if you want to update the timestamp

    const docRef = doc(firebase, COLLECTIONS.CLIENT, id);
    await updateDoc(docRef, updates); 
    return true; 
  } catch (error) {
    console.error('Error deleting client shift:', error); 
    return null; 
  }
}
/**
 * Upload logo of client :: not used for now need to change logo upload
 * @param {*} file
 * @param {*} onSuccess
 */
export async function uploadLogo(file, onSuccess) {
  try {
    const storage = getStorage();
    const storageRef = ref(storage, 'images/' + file.name);

    const metadata = {
      contentType: 'image/jpeg',
    };

    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');

        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
          default:
            break;
        }
      },
      (error) => {
        console.error('Upload failed:', error);
        switch (error.code) {
          case 'storage/unauthorized':
            console.error('User doesn\'t have permission to access the object');
            break;
          case 'storage/canceled':
            console.error('User canceled the upload');
            break;
          case 'storage/unknown':
            console.error('Unknown error occurred, inspect error.serverResponse');
            break;
          default:
            console.error('Error:', error);
            break;
        }
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          onSuccess && onSuccess(downloadURL);
        } catch (urlError) {
          console.error('Failed to get download URL:', urlError);
        }
      }
    );
  } catch (error) {
    console.error('Failed to upload logo:', error);
  }
}

/**
 * Get Document list from client collection for summary
 * @param {*} formData : can be use for filter in future
 * @param {*} onSuccess
 * @param {*} onError
 */
// export const getClientsSummary = (formData, onSuccess, onError) => {
//   let colRef = firebase.firestore().collection(collection);
//   colRef
//     .limit(5)
//     .get()
//     .then((querySnapshot) => {
//       let results = [];
//       querySnapshot.forEach((doc) => {
//         let data = doc.data();
//         results.push({ id: doc.id, ...data });
//       });
//       onSuccess && onSuccess(results);
//     })
//     .catch((error) => {
//       console.log('Error getting documents: ', error);
//       onError && onError(error);
//     });
// };

// export const updateClientNumbers = async () => {
//   const colRef = collection(firebase, COLLECTIONS.CLIENT);

//   try {
//     const querySnapshot = await getDocs(colRef);
//     let clientNumber = 101;

//     for (const docSnapshot of querySnapshot.docs) {
//       const docRef = doc(firebase, COLLECTIONS.CLIENT, docSnapshot.id);
//       await updateDoc(docRef, {
//         clientNumber: clientNumber,
//       });
//       clientNumber++;
//     }

//     console.log("Client numbers have been successfully updated!");
//   } catch (error) {
//     console.error("Error updating client numbers:", error);
//   }
// };

// export const updateTimesheetRecords = async () => {
//   try {
//     const snapshot = await getDocs(collection(firebase, COLLECTIONS.TIMESHEETS));
    
//     for (let i = 0; i < snapshot.docs.length; i++) {
//       const doc = snapshot.docs[i];
//       const record = doc.data();
//       const appointmentDateTime = record.appointmentDateTime;
//       const timeZone = record.apoointmentDetails.calendarTimezone || "America/New_York";

//       const date = moment(appointmentDateTime).tz(timeZone);
//       const formattedDate = date.format("MMMM D, YYYY");

//       await updateDoc(doc.ref, { appointmentDate: formattedDate });
      
//       console.log(`Updated record ID: ${doc.id} with appointmentDate: ${formattedDate}`);
//     }

//     console.log('All records updated successfully.');
//   } catch (error) {
//     console.error('Error updating records:', error);
//   }
// };