import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AuthContext from '../../../hooks/authContext';

const DayView = ({ allDays, clientNameList, empNames, employeeDetails }) => {
    const timeSlots = Array.from({ length: 13 }, (_, i) => i + 7);
    const timeZones = [
        { label: "EDT - Eastern", value: "America/New_York" },
        { label: "CDT - Central", value: "America/Chicago" },
        { label: "MDT - Mountain", value: "America/Denver" },
        { label: "PDT - Pacific", value: "America/Los_Angeles" }
    ];
    const auth = useContext(AuthContext);
    const appointmentData = auth.appointmentsData;
    const setIsModalOpen = auth.setIsModalOpen;
    const setSelectedAppointment = auth.setSelectedAppointment;
    const [widths, setWidths] = useState();

    const formatTime = (hour) => {
        const ampm = hour >= 12 ? 'pm' : 'am';
        const formattedHour = hour % 12 || 12;
        return `${formattedHour}${ampm}`;
    };

    const gruopData = Object.entries(
        employeeDetails && employeeDetails?.reduce((map, employee) => {
            if (!map[employee.timeZone]) {
                map[employee.timeZone] = [];
            }
            map[employee.timeZone].push(employee);
            return map;
        }, {})
    )

    const isCurrentTimeSlot = (appointmentTimeSlot, currentTime, timezone) => {
        const currentMoment = moment().tz(timezone);
        const appointmentMoment = moment().hour(appointmentTimeSlot).minute(0).second(0).millisecond(0).tz(timezone);
        return currentMoment.isSame(appointmentMoment);
    };

    useEffect(() => {
        const width = gruopData.flatMap(([timeZone, employees]) =>
            employees.map((employee) => {
                const employeeAppointments = appointmentData.filter(
                    (item) =>
                        item.userId === employee.userId &&
                        moment(item.date).format("MMMM DD YYYY") === moment(allDays).format("MMMM DD YYYY")
                );

                if (employeeAppointments.length === 0) return 300;
                if (employeeAppointments.length === 1) return 300;
                return (employeeAppointments.length) * 200;
            })
        )
        setWidths(width)
    }, [allDays]);

    return (
        <div className="calendar-container">
            <div className="calendar-grid">
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: `80px ${widths?.map((width) => `${width}px`).join(" ")}`,
                    }}
                >
                    <div className="time-column">
                        <AccessTimeIcon fontSize="small" />
                    </div>
                    {employeeDetails && gruopData?.map(([timeZone, employees], index) => (
                        <React.Fragment key={index}>
                            {employees.map((employee) => (
                                <>
                                    <div key={employee.id} className="daily-header">
                                        <div>
                                            {employee.firstName} {employee.lastName} -{" "}
                                            {timeZones.find((time) => employee.timeZone === time.value)?.label.split(" ")[0]}
                                        </div>
                                    </div>
                                </>
                            ))}
                        </React.Fragment>
                    ))}
                </div>

                {timeSlots.map((hour) => (
                    <div
                        key={hour}
                        // className={`day-grid-row ${hour === 18 ? 'highlight-row' : ''}`}
                        style={{
                            display: "grid",
                            gridTemplateColumns: `80px ${widths?.map((width) => `${width}px`).join(" ")}`,
                        }}
                    >
                        <div className="time-column">{formatTime(hour)}</div>
                        {gruopData?.map(([timeZone, employees], index) => (
                            <React.Fragment key={index}>
                                {employees.map((employee) => {
                                    const appointmentsForEmployee = appointmentData.filter((appointment) => {
                                        return (appointment.userId === employee.userId && moment(appointment.date).format("MMMM DD YYYY") === moment(allDays).format("MMMM DD YYYY"));
                                    });

                                    const filteredAppointments =
                                        empNames !== "All"
                                            ? appointmentsForEmployee.filter((item) => item.userId === empNames)
                                            : appointmentsForEmployee;
                                    return (
                                        <div key={`${employee.id}-${hour}`} className="time-slot">
                                            {filteredAppointments.map((appointment, index) => {
                                                const appointmentStartTime = moment(appointment.time, "hh:mm a");
                                                const appointmentEndTime = moment(appointment.endTime, "hh:mm a");
                                                const appointmentTimeSlot = moment(appointment.time, "hh:mm a").hour();
                                                const client = clientNameList.find((client) => client.value === appointment.clientId);
                                                const boxHeight = (appointment.duration / 60) * 100;
                                                const overlappingAppointments = filteredAppointments.filter((otherAppointment) => {
                                                    const otherStartTime = moment(otherAppointment.time, "hh:mm a");
                                                    const otherEndTime = moment(otherAppointment.endTime, "hh:mm a");

                                                    return (
                                                        (appointmentStartTime.isBefore(otherEndTime) && appointmentEndTime.isAfter(otherStartTime)) &&
                                                        otherAppointment !== appointment
                                                    );
                                                });

                                                const overlapCount = overlappingAppointments.length + 1;
                                                const appointmentWidth = 100 / overlapCount;
                                                const horizontalOffset = (index % overlapCount) * appointmentWidth;
                                                if (appointmentTimeSlot === hour) {
                                                    return (
                                                        <div key={appointment.id}
                                                            className={`appointmentView ${isCurrentTimeSlot(hour, moment(), timeZone) ? 'redline' : ''}`}
                                                            style={{
                                                                height: `${boxHeight}px`,
                                                                width: `calc(100% / ${filteredAppointments.length})`,
                                                                left: `${(index % filteredAppointments.length) * (100 / filteredAppointments.length)}%`,
                                                            }}
                                                            onClick={() => {
                                                                setIsModalOpen(true);
                                                                setSelectedAppointment(appointment);
                                                            }}
                                                        >
                                                            <div className="appointmentView-title">
                                                                {client.label} - <span style={{ fontWeight: "450" }}>{appointment.calendarTimezone}</span>
                                                            </div>
                                                            <div className="appointmentView-details mv-10">
                                                                {appointment.time} - {appointment.endTime}
                                                            </div>
                                                            <div className="appointmentView-details2 mv-10">
                                                                {appointment.shiftName}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            })}
                                        </div>
                                    );
                                })}
                            </React.Fragment>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );


};

export default DayView;