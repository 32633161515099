/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import PageTitle from "../../components/pageTitle/PageTitle";
import { Button, Container } from "@material-ui/core";
import { getAllEmployee } from "../../services/apiservices/employee";
import SingleSelect from "../../components/SelectField/SingleSelect";
import { DeleteAppointment, getAllAppoinment } from "../../services/apiservices/appoinments";
import { getClientsList } from "../../services/apiservices/client";
import { useNavigate } from "react-router";
import { useMediaQuery } from "@mui/material";
import AuthContext from "../../hooks/authContext";
import { DeleteApptDialog, SuccessDeleteApptDialog } from "../../components/DialogComponent/SupplyDataDialog";
import { AppointmentModal, AppointmentsDetailComponent } from "./Models/AppointmentModel";
import MonthView from "./Calendar/MonthViews";
import WeekView from "./Calendar/WeekViews";
import NavBar from "./Calendar/NavBar/Navigations";
import DayView from "./Calendar/DayViews";

const ManageAppointments = () => {
  const [empNames, setEmpNames] = useState("All");
  const [employeeNameList, setEmployeeNameList] = useState([
    { label: "All", value: "All" },
  ]);
  const [clientNameList, setClientNameList] = useState([]);
  const [clientDetails, setClientDetails] = useState();
  const [employeeDetails, setEmployeeDetails] = useState();
  const [allEmployees, setAllEmployees] = useState();
  const [appoinments, setAppoinments] = useState([]);
  let navigate = useNavigate();
  const [upcomingAppointments, setUpcomingAppointments] = useState({});
  const isMobile = useMediaQuery("(max-width:767px)");
  const isLandscape = useMediaQuery(
    "(min-width: 771px) and (max-width: 940px)"
  );
  const [filteredAppointment, setFilteredAppointments] = useState([]);
  const [cancelAppt, setCancelAppt] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const auth = useContext(AuthContext);
  const [view, setView] = useState('month');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [weekDates, setWeekDates] = useState([]);
  const [selectedDayDate, setSelectedDayDate] = useState();
  const editAppointmentData = auth?.editAppointmentData?.selectedAppointment;
  const updateAppointmentData = auth.updateAppointmentData?.matchingAppointments;
  const appointmentRefs = useRef({});
  const isModalOpen = auth.isModalOpen;
  const setIsModalOpen = auth.setIsModalOpen;
  const selectedAppointment = auth.selectedAppointment;
  const setSelectedAppointment = auth.setSelectedAppointment;

  const ManageAppointmentsData = async () => {
    const appointmentResponse = await getAllAppoinment();
    if (appointmentResponse) {
      const filterAppointments = appointmentResponse?.appointments.filter(item => item?.canceled !== true);
      setAppoinments(filterAppointments);
      auth.setAppointmentsData(filterAppointments);
      const upcomingAppointments = filterAppointments.sort(
        (a, b) => {
          if (a.date !== b.date) return a.date.localeCompare(b.date);
          return a.time.localeCompare(b.time);
        }
      );

      const groupAppointmentsByDate = upcomingAppointments.reduce(
        (grouped, appointment) => {
          const { date } = appointment;
          if (!grouped[date]) grouped[date] = [];
          grouped[date].push(appointment);
          return grouped;
        },
        {}
      );
      setUpcomingAppointments(groupAppointmentsByDate);
      const currentMonth = new Date().getMonth();

      const filtered = Object.keys(groupAppointmentsByDate).filter((date) => {
        const appointmentDate = new Date(date);
        return appointmentDate.getMonth() === currentMonth;
      });

      const filteredAppointments = filtered.reduce((result, date) => {
        result[date] = groupAppointmentsByDate[date];
        return result;
      }, {});

      setFilteredAppointments(filteredAppointments);
    } else {
      setAppoinments([]);
      auth.setAppointmentsData({});
      setUpcomingAppointments({});
      setFilteredAppointments([]);
    }
    await getClientsList({}, (res) => {
      if (res) {
        setClientDetails(res);
        const clientNames = res.map((client) => ({
          value: client?.id,
          label: client?.clientName,
          firstName: client?.firstName,
          lastName: client?.lastName,
        }));
        setClientNameList(clientNames);
      }
    });
    await getAllEmployee((res) => {
      setAllEmployees(res);
      const employee = res?.data.filter((item => item.role === "Employee"));
      setEmployeeDetails(employee);
      const employeeNames = employee?.map((emp) => ({
        value: emp?.id,
        client: emp?.clients,
        label: `${emp?.firstName} ${emp?.lastName}`,
        email: emp?.email,
      }));
      setEmployeeNameList([{ label: "All", value: "All" }, ...employeeNames]);
    });
  };

  useEffect(() => {
    ManageAppointmentsData();
  }, []);

  const handleEmpNameChange = (e) => {
    const selectEmployee = e.target.value;
    setEmpNames(selectEmployee);
  };

  const handelCancelCloseDialog = () => {
    setCancelAppt(false);
  }

  const handelSingleOrSeriesApptCancelDialog = async (series = false) => {
    const ids = series ? updateAppointmentData.map(item => item.id) : [editAppointmentData?.id];
    setCancelAppt(false);
    const response = await DeleteAppointment(ids);
    if (response.success) {
      setIsDeleteDialogOpen(true);
    } else {
      console.error("Failed to save appointment:", response.error);
    }
  }

  const handelSuccelDeleteCloseDialog = async () => {
    setIsDeleteDialogOpen(false);
    setIsModalOpen(false);
    setCancelAppt(false);
    auth.setEditAppointmentData({});
    auth.setUpdateAppointmentData({});
    ManageAppointmentsData();
  };


  const redirectToSchedule = () => {
    navigate("/appoinmentSchedule");
  };

  const handleScrollToAppointments = (date) => {
    const dateKey = date.format("MMMM D, YYYY");
    const targetRef = appointmentRefs.current[dateKey];
    if (targetRef) {
      targetRef.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleAppointmentOpen = (appointment) => {
    setIsModalOpen(true);
    setSelectedAppointment(appointment);
  };

  return (
    <>
      <Container className="containers">
        <PageTitle
          pageTitle="View Schedule"
          showBreadcrumb={[
            { label: "Home", to: "/" },
            { label: "Appoinments" },
          ]}
          BreadCrumbCurrentPageTitle={"View Schedule"}
          showLastSeen={true}
        />
        <div className="sectionBox">
          <div className="d-flex flex-justify-flexend flex-center mb-15">
            {isMobile ?
              <div style={{ width: isMobile || isLandscape ? "250px" : "300px" }}>
                <SingleSelect
                  value={empNames}
                  name={"employeeName"}
                  label={"Employee Name"}
                  onChange={handleEmpNameChange}
                  options={employeeNameList}
                  datetime={true}
                />
              </div> : null}
            <div className="ml-20">
              <Button
                variant="contained"
                className={`${isMobile || isLandscape ? "pv-10 ph-10" : "pv-10 ph-20"
                  }`}
                style={{ backgroundColor: "green", color: "white" }}
                onClick={redirectToSchedule}
              >
                Schedule Now
              </Button>
            </div>
          </div>

          <NavBar
            empNames={empNames}
            handleEmpNameChange={handleEmpNameChange}
            employeeNameList={employeeNameList}
            setFilteredAppointments={setFilteredAppointments}
            upcomingAppointments={upcomingAppointments}
            setView={setView}
            view={view}
            setCurrentDate={setCurrentDate}
            currentDate={currentDate}
            weekDates={weekDates}
            setWeekDates={setWeekDates}
            setSelectedDayDate={setSelectedDayDate}
            selectedDayDate={selectedDayDate}
          />

          {view === 'month' && (
            <MonthView
              clientNameList={clientNameList}
              handleScrollToAppointments={handleScrollToAppointments}
              appointmentRefs={appointmentRefs}
              setFilteredAppointments={setFilteredAppointments}
              upcomingAppointments={upcomingAppointments}
              currentDate={currentDate}
              empNames={empNames}
            />
          )}

          {view === 'week' && (
            <WeekView
              weeksAllDays={weekDates}
              clientNameList={clientNameList}
              empNames={empNames} />
          )}

          {view === 'day' && (
            <DayView
              allDays={selectedDayDate}
              clientNameList={clientNameList}
              empNames={empNames}
              employeeDetails={employeeDetails} />
          )}

          <div className="mt-40">
            <AppointmentsDetailComponent
              appointments={filteredAppointment}
              handleAppointmentOpen={handleAppointmentOpen}
              appointmentRefs={appointmentRefs}
            />
          </div>
          {selectedAppointment && (
            <ScheduleModal
              isModalOpen={isModalOpen}
              selectedAppointment={selectedAppointment}
              isMobile={isMobile}
              isLandscape={isLandscape}
              clientDetails={clientDetails}
              employeeDetails={allEmployees}
              appoinments={appoinments}
              auth={auth}
              setIsModalOpen={setIsModalOpen}
              setCancelAppt={setCancelAppt}
            />
          )}
        </div>
      </Container>

      {cancelAppt && (
        <DeleteApptDialog open={cancelAppt} onSingleAppt={() => handelSingleOrSeriesApptCancelDialog(false)} onConfirm={() => handelSingleOrSeriesApptCancelDialog(true)}
          auth={editAppointmentData} update={updateAppointmentData} onClose={handelCancelCloseDialog} />
      )}
      {isDeleteDialogOpen && (
        <SuccessDeleteApptDialog open={isDeleteDialogOpen} onClose={handelSuccelDeleteCloseDialog} />
      )}
    </>
  );
};

const ScheduleModal = (props) => {
  const { isModalOpen } = props;
  const [isModalO, setIsModalO] = useState(false);


  useEffect(() => {
    if (isModalOpen) {
      setIsModalO(isModalOpen);
    } else {
      setTimeout(() => {
        setIsModalO(isModalOpen);
      }, 250);
    }

  }, [isModalOpen]);

  const handleOverlayClick = (e) => {
    if (e.target?.classList?.contains('modal-overlay')) {
      props?.setIsModalOpen(false);
      props?.auth.setEditAppointmentData({});
      props?.auth.setUpdateAppointmentData({});
    }
  };

  return isModalO ? (
    <div className={`modal-overlay active`} onClick={handleOverlayClick} >
      <div className={`modal-content ${isModalOpen ? 'slideUpModal' : 'slideDownModal'}`}>
        {" "}
        <AppointmentModal {...props} />{" "}
      </div>
    </div>
  ) : null;
};
export default ManageAppointments;
