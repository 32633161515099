import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../../hooks/authContext';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const WeekView = ({ weeksAllDays, clientNameList, empNames }) => {
    const timeSlots = Array.from({ length: 13 }, (_, i) => i + 7);
    const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const [widths, setWidths] = useState([]);
    const timeZones = [
        { label: "EDT - Eastern", value: "America/New_York" },
        { label: "CDT - Central", value: "America/Chicago" },
        { label: "MDT - Mountain", value: "America/Denver" },
        { label: "PDT - Pacific", value: "America/Los_Angeles" }
    ];
    const auth = useContext(AuthContext);
    const appointments = auth.appointmentsData;
    const setIsModalOpen = auth.setIsModalOpen;
    const setSelectedAppointment = auth.setSelectedAppointment;

    const formatTime = (hour) => {
        const ampm = hour >= 12 ? 'pm' : 'am';
        const formattedHour = hour % 12 || 12;
        return `${formattedHour}${ampm}`;
    };

    const getAppointmentsForDay = (date) => {
        if (Array.isArray(appointments)) {
            const weekDateString = new Date(date).toISOString().split('T')[0];

            const filteredAppointments = appointments.filter((item) => {
                const appointmentDate = new Date(item.date);
                const appointmentDateString = appointmentDate.toISOString().split('T')[0];
                return appointmentDateString === weekDateString;
            });

            const normalizedDate = moment(date).startOf('day').utc().format("MMMM DD YYYY");

            return filteredAppointments.filter((appointment) => {
                const appointmentDate = moment(appointment.date).startOf('day').utc().format("MMMM DD YYYY");
                return appointmentDate === normalizedDate;
            });
        }
        return [];
    };

    useEffect(() => {
        const widthsArray = [];

        weeksAllDays.forEach((day) => {
            const dailyAppointments = getAppointmentsForDay(day, appointments);

            timeZones.forEach((timezone) => {
                const timezoneAppointments = dailyAppointments.filter(
                    (appointment) =>
                        (appointment.calendarTimeZone || "America/New_York") === timezone.value
                );

                let overlaps = 0;

                timezoneAppointments.forEach((appointment) => {
                    const start = moment(appointment.time, "hh:mm a").tz(timezone.value);
                    const end = moment(appointment.endTime, "hh:mm a").tz(timezone.value);

                    const overlappingAppointments = timezoneAppointments.filter((other) => {
                        const otherStart = moment(other.time, "hh:mm a").tz(timezone.value);
                        const otherEnd = moment(other.endTime, "hh:mm a").tz(timezone.value);
                        return start.isBefore(otherEnd) && end.isAfter(otherStart) && appointment !== other;
                    });

                    overlaps = Math.max(overlaps, overlappingAppointments.length + 1);
                });

                widthsArray.push(Math.max(250, overlaps * 200));
            });
        });
        setWidths(widthsArray);
    }, [weeksAllDays]);

    return (
        <div className="calendar-container">
            <div className="calendar-grid">
                <div className="grid-row-day" style={{ display: "grid", gridTemplateColumns: `80px ${widths?.map((width) => `${width}px`).join(" ")}`  }}>
                    <div className="time-column"></div>
                    {weeksAllDays.map((day, index) => (
                        <div key={`day-${index}`} className="day-header" style={{ gridColumn: 'span 4' }}>
                            {moment(day).format("dddd MMM D")}
                        </div>
                    ))}
                </div>

                <div className="grid-row-Time" style={{ display: "grid",  gridTemplateColumns: `80px ${widths?.map((width) => `${width}px`).join(" ")}` }}>
                    <div className="time-column"><AccessTimeIcon fontSize="small" /></div>
                    {weekDays.map(day =>
                        timeZones.map((timezone) => (
                            <div key={`${day}-${timezone.value}`} className="timezone-header">
                                {`${day.slice(0, 3)} ${timezone.label.split(" ")[0]}`}
                            </div>
                        ))
                    )}
                </div>

                {timeSlots.map((hour) => (
                    <div
                        key={hour}
                        style={{ display: "grid", gridTemplateColumns: `80px ${widths?.map((width) => `${width}px`).join(" ")}`, }}
                    >
                        <div className="time-column">
                            {formatTime(hour)}
                        </div>

                        {weeksAllDays.map((day, dayIndex) => {
                            const dailyAppointments = getAppointmentsForDay(day);

                            return timeZones.map((timezone) => {
                                const timezoneAppointments = dailyAppointments.filter((appointment) =>
                                    (appointment.calendarTimeZone || "America/New_York") === timezone.value
                                );

                                const filteredAppointments = empNames !== "All"
                                    ? timezoneAppointments.filter((item) => item?.userId === empNames)
                                    : timezoneAppointments;

                                return (
                                    <div className="time-slot" key={`timezone-${timezone.value}-${dayIndex}`}>
                                        {filteredAppointments.map((appointment, index) => {
                                            const client = clientNameList.find((client) => client.value === appointment.clientId);
                                            const appointmentTimeSlot = moment(appointment.time, "hh:mm a").hour();
                                            const boxHeight = (appointment.duration / 60) * 100;

                                            if (appointmentTimeSlot === hour) {
                                                return (
                                                    <div
                                                        key={`appointment-${index}`}
                                                        className="appointmentView"
                                                        style={{
                                                            height: `${boxHeight}px`,
                                                            width: `calc(100% / ${filteredAppointments.length})`,
                                                            left: `${(index % filteredAppointments.length) * (100 / filteredAppointments.length)}%`,
                                                        }}
                                                        onClick={() => {
                                                            setIsModalOpen(true);
                                                            setSelectedAppointment(appointment);
                                                        }}
                                                    >
                                                        <div className="appointmentView-title">
                                                            {client?.label} - <span style={{ fontWeight: "450" }}>{appointment.calendarTimezone}</span>
                                                        </div>
                                                        <div className="appointmentView-details mv-10">
                                                            {appointment.time} - {appointment.endTime}
                                                        </div>
                                                        <div className="appointmentView-details2 mv-10">
                                                            {appointment.shiftName}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}
                                    </div>
                                );
                            });
                        })}
                    </div>
                ))}
            </div>
        </div>
    );
};


export default WeekView;