import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import {
  APIProvider,
  ControlPosition,
  MapControl,
  AdvancedMarker,
  Map,
  useMap,
  useMapsLibrary,
  useAdvancedMarkerRef,
  AdvancedMarkerRef,

} from "@vis.gl/react-google-maps";
import { TextField} from "@material-ui/core";
import MapPin from "../../../assets/images/icons8-marker.png";
import internal from "stream";
const API_KEY = "AIzaSyCQu0ku4myhKsSAsQ5BX6vseHJ9bPCyAvc";

function AddressByMap({ onChangeAddress, addressInfo }) {
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [markerRef, marker] = useAdvancedMarkerRef();
  const [center, setCenter] = useState();
  const [internalCenter, setInternalCenter] = useState();

  const mapRef = useRef(null);

  useEffect(()=>{
    if(addressInfo && !addressInfo?.lat && !addressInfo.lng) {
      const fullAddress = `${addressInfo.address}, ${addressInfo.city}, ${addressInfo.state}, ${addressInfo.zip}`;
      if (fullAddress.trim().length > 0) {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: fullAddress }, (results, status) => {
          if (status === "OK" && results[0]) {
            const location = results[0].geometry.location;
            setCenter({detail: {center: {lat:location.lat(), lng: location.lng()}}});
          }
        });
      }else{
        setCenter({detail: {center: {lat:addressInfo?.lat, lng: addressInfo?.lng}}});
      }
    }
  }, [addressInfo]);


  const onCenterChange = async (region) => {
    setCenter({detail: {center: {lat:region.lat, lng: region.lng}}});
  };
  const onSetAddresss = (place) =>{
    const lt = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    if (!(center?.detail?.center?.lat === lt && center?.detail?.center?.lng === lng)) {
      fillInAddress(place);
    } else {
      fillInAddress(place);
    }
    
  }
  const onSelectPlace = (palce) => {
    setSelectedPlace(palce);
    fillInAddress(palce);

    // map && map.setCenter({lat: palce.geometry.location.lat(), lng: palce.geometry.location.lng()});
    // setCenter({lat: palce.geometry.location.lat(), lng: palce.geometry.location.lng()});
  };

  function fillInAddress(place) {
    // Get the place details from the autocomplete object.

    let address1 = "";
    let postcode = "";
    let state = "";
    let country = "";
    let city = "";

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    // place.address_components are google.maps.GeocoderAddressComponent objects
    // which are documented at http://goo.gle/3l5i5Mr
    for (const component of place?.address_components) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number": {
          address1 = `${component?.long_name} ${address1}`;
          break;
        }
        case "route": {
          address1 += component?.short_name;
          break;
        }

        case "postal_code": {
          postcode = `${component?.long_name}${postcode}`;
          break;
        }

        case "postal_code_suffix": {
          postcode = `${postcode}-${component?.long_name}`;
          break;
        }

        case "administrative_area_level_1": {
          state = component.short_name;
          break;
        }
        case "locality":
          city = component.long_name;
          break;
        case "country":
          country = component.long_name;
          break;
        default:
          break;
      }
    }
    onChangeAddress &&
      onChangeAddress({
        address: address1,
        state: state,
        zip: postcode,
        city: city,
        lat: place?.geometry.location.lat(),
        lng: place?.geometry.location.lng(),
      });
  }

  return (
    <APIProvider
      apiKey={API_KEY}
      solutionChannel="GMP_devsite_samples_v3_rgmautocomplete"
    >
      <Map
        mapId={"bf51a910020fa25a"}
        ref={mapRef}
        defaultZoom={12}
        mapTypeControl={false}
        streetViewControl={false}
        defaultCenter={center || { lat: 22.54992, lng: 0 }}
        gestureHandling={"greedy"}
        onDragend = {(eve) => {
          onCenterChange({lat: eve.map.center?.lat(), lng: eve.map.center?.lng()});
          // console.log(eve.map.center?.lat());
        }}
        // onCenterChanged={onCenterChange}
      >
        <div
          style={{
            left: "50%",
            marginLeft: -24,
            marginTop: -48,
            position: "absolute",
            top: "50%",
          }}
        >
          <img src={MapPin} style={{ height: 48 }}></img>
        </div>
        {/* <AdvancedMarker ref={markerRef} position={null} /> */}
      </Map>
      <MapControl position={ControlPosition.TOP}>
        <div className="autocomplete-control">
          <PlaceAutocomplete
            onPlaceSelect={onSelectPlace}
            placeLoaded={center}
            onSetAddresss = {onSetAddresss}
            mapCenter = {internalCenter}
          />
        </div>
      </MapControl>

      <MapHandler place={selectedPlace} marker={marker} />
    </APIProvider>
  );
}

const MapHandler = ({ place, marker }) => {
  const map = useMap();

  useEffect(() => {
    if (!map || !place) return;

    if (place.geometry?.viewport) {
      map.fitBounds(place.geometry?.viewport);
    }
  }, [map, place, marker]);

  return null;
};

const PlaceAutocomplete = ({ onPlaceSelect, placeLoaded, onSetAddresss, mapCenter }) => {
  
  const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
  const [manualAddress, setManualAddres] = useState("");
  const inputRef = useRef(null);
  const map = useMap();
  const places = useMapsLibrary("places");
  const geocodingLib = useMapsLibrary("geocoding");
  const geocoder = useMemo(
    () => geocodingLib && new geocodingLib.Geocoder(),
    [geocodingLib]
  );

  const findPlace = async () => {
    try {
      if (placeLoaded?.detail?.center) {
        console.log(placeLoaded?.detail?.center);
        const geoaddress = await geocoder.geocode({
          location: placeLoaded.detail.center
        });
        map.setCenter(placeLoaded.detail.center);
        if (!inputRef.current) return;
        const options = {
          componentRestrictions: { country: ["us", "ca"] },
          fields: [
            "address_components",
            "geometry",
            "name",
            "formatted_address",
          ],
          types: ["address"],
        };
        
        inputRef.current.value = geoaddress?.results[0]?.formatted_address;
        setPlaceAutocomplete(
          new places.Autocomplete(inputRef.current, options)
        );
        onSetAddresss(geoaddress.results[0]);
      }
    } catch (e) {
      console.log("Erere", e);
    }
  };

  const findPlaceNew = useCallback(async()=>{
    try {
      if (placeLoaded?.detail?.center) {
        
        const geoaddress = await geocoder.geocode({
          location: placeLoaded.detail.center
        });
        map.setCenter(placeLoaded.detail.center);
        if (!inputRef.current) return;
        const options = {
          componentRestrictions: { country: ["us", "ca"] },
          fields: [
            "address_components",
            "geometry",
            "name",
            "formatted_address",
          ],
          types: ["address"],
        };
        
        inputRef.current.value = geoaddress?.results[0]?.formatted_address;
        // setPlaceAutocomplete(
        //   new places.Autocomplete(inputRef.current, options)
        // );
        onSetAddresss(geoaddress.results[0]);
      }
    } catch (e) {
      console.log("Erere", e);
    }

  }, [mapCenter]);

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      componentRestrictions: { country: ["us", "ca"] },
      fields: ["address_components", "geometry", "name", "formatted_address"],
      types: ["address"],
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    findPlace();
    // console.log(placeLoaded);
  }, [placeLoaded]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener("place_changed", () => {
      onPlaceSelect(placeAutocomplete.getPlace());
    });
  }, [onPlaceSelect, placeAutocomplete]);

  return (
    <div className="autocomplete-container">
      {/* <TextField
                  fullWidth={true}
                  size="small"
                  label="Location"
                  variant="outlined"
                  placeholder="Search Locaiton here"
                  ref={inputRef}
                /> */}
      <input ref={inputRef} placeholder="Search location..."  style={{borderWidth: 1, backgroundColor:'#fff', height:35, width: 320, borderRadius:4, padding:5, borderColor:'#aaaaaa', marginTop:5}}/>
    </div>
  );
};

export default AddressByMap;
