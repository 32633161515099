import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, Divider, Popover, Typography } from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";

export function SuppplyDataDialog({ open, onClose, listData }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="body" className="mt-20" style={{
        fontSize: '22px', fontWeight: "800", color: "#3d3d3d", paddingRight: "10px", paddingLeft: "10px",
        paddingTop: "5px", paddingBottom: "5px", marginLeft: "12px"
      }}>
        Supplies
        <Divider className="mt-10" />
      </Typography>
      <DialogContent style={{ paddingTop: "5px", paddingBottom: "5px" }}>
        {Array.isArray(listData) ? (
          <div>
            {listData.map((item, index) => (
              <div key={index} className="mv-10">
                <Typography variant="body" style={{ color: "#606060", fontSize: "16px" }}>{item?.title} ({item?.qty})</Typography>
              </div>
            ))}
          </div>
        ) : (
          <div>{listData}</div>
        )}
      </DialogContent>
      <DialogActions className="d-flex flex-justify-center">
        <Button variant="contained" onClick={onClose} className="ph-30 mb-15" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function SuccessDialog({ open, onClose, Archive }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="body" className="mt-20" style={{
        fontSize: '22px', fontWeight: "800", color: "#3d3d3d", paddingRight: "10px", paddingLeft: "10px",
        paddingTop: "5px", paddingBottom: "5px", marginLeft: "12px"
      }}>
        Success
        <Divider className="mt-10" />
      </Typography>
      <DialogContent style={{ paddingTop: "5px", paddingBottom: "5px" }}>
        {`${Archive === true ? 'The order has been UnArchived and is in process. Please review in Manage Supplies.' : 'The order has been approved and is in process. Please review in Manage Supplies.'}`}
      </DialogContent>
      <DialogActions className="d-flex flex-justify-center">
        <Button variant="contained" onClick={onClose} className="ph-30 mb-15" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function SuccessGroupDeleteDialog({ open, onClose, group }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <div className="d-flex flex-center flex-direction-column">
        <DialogContent className="mb-10 mt-30" style={{ fontSize: "18px", lineHeight: "22px" }}>
          You have successfully deleted the {group?.title} Group
        </DialogContent>
        <DialogActions className="mb-20">
          <Button onClick={onClose} variant="contained" className="ph-35" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
            Close
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export function RejectDailog({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        The order has been rejected and has been added to Archived Orders.
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function ConfirmationsDialog({ open, onClose, onConfirm }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="body" className="mt-25" style={{ fontSize: '20px', fontWeight: "800", color: "#3d3d3d", padding: "10px", marginLeft: "12px" }}>
        Confirm Approval
        <Divider className="mt-15" /></Typography>
      <DialogContent className="mb-10">
        Are you sure you want to approve this request?
      </DialogContent>
      <DialogActions className="mt-20 mb-15" >
        <Button variant="contained" onClick={onClose} className="ph-35 mr-5" style={{ backgroundColor: "gray", color: "white", borderRadius: "10px" }}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onConfirm} className="ph-35 mr-10" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function ConfirmationsUnArchiveDialog({ open, onClose, onConfirm }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="body" className="mt-25" style={{ fontSize: '20px', fontWeight: "800", color: "#3d3d3d", padding: "10px", marginLeft: "12px" }}>
        Unarchive
        <Divider className="mt-15" /></Typography>
      <DialogContent className="mb-10">
        Are you sure you want to "UnArchive" this item?
      </DialogContent>
      <DialogActions className="mt-20 mb-15" >
        <Button variant="contained" onClick={onClose} className="ph-35 mr-5" style={{ backgroundColor: "gray", color: "white", borderRadius: "10px" }}>
          No
        </Button>
        <Button variant="contained" onClick={onConfirm} className="ph-35 mr-10" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function ConfirmationDeleteChannelDialog({ open, onClose, onConfirm, chItem }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="body" className="mt-25" style={{ fontSize: '20px', fontWeight: "800", color: "#3d3d3d", padding: "10px", marginLeft: "12px" }}>
        Remove Channel
        <Divider className="mt-15" /></Typography>
      <DialogContent className="mb-10">
        Are you Sure you want to permanent remove "{chItem ? chItem['groupName'] : ""}" channel?
      </DialogContent>
      <DialogActions className="mt-20 mb-15" >
        <Button variant="contained" onClick={onClose} className="ph-35 mr-5" style={{ backgroundColor: "gray", color: "white", borderRadius: "10px" }}>
          No
        </Button>
        <Button variant="contained" onClick={onConfirm} className="ph-35 mr-10" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function CustomTooltip({ listData, title }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        className="color-Darkblue"
        style={{
          cursor: "pointer",
        }}
      >{title}
      </Typography>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="tooltip-heading2 p-5">
          <div className="tooltip-container2">
            {Array.isArray(listData) ? (
              <div>
                {listData.map((item, index) => (
                  <div key={index}>
                    <div>{item.title} ({item.qty})</div>
                  </div>
                ))}
              </div>
            ) : (
              <div>{listData}</div>
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
};

export function SuccessApptDialog({ open, onClose, modal }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="body" className="mt-20" style={{
        fontSize: '22px', fontWeight: "800", color: "#3d3d3d", paddingRight: "10px", paddingLeft: "10px",
        paddingTop: "5px", paddingBottom: "5px", marginLeft: "12px"
      }}>
        Confirmation
        <Divider className="mt-10" />
      </Typography>
      <DialogContent style={{ paddingTop: "5px", paddingBottom: "5px" }}>
        {modal ? 'Appoinment successfully Updated! Thank You for your prompt scheduling. ' : 'Appoinment successfully Created! Thank You for your prompt scheduling.'}
      </DialogContent>
      <DialogActions className="d-flex flex-justify-center">
        <Button variant="contained" onClick={onClose} className="ph-30 mb-15" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function SuccessDeleteApptDialog({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="body" className="mt-20" style={{
        fontSize: '22px', fontWeight: "800", color: "#3d3d3d", paddingRight: "10px", paddingLeft: "10px",
        paddingTop: "5px", paddingBottom: "5px", marginLeft: "12px"
      }}>
        Confirmation
        <Divider className="mt-10" />
      </Typography>
      <DialogContent style={{ paddingTop: "5px", paddingBottom: "5px" }}>
        Appoinment successfully Deleted! Thank You for your prompt scheduling.
      </DialogContent>
      <DialogActions className="d-flex flex-justify-center">
        <Button variant="contained" onClick={onClose} className="ph-30 mb-15" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function ConfirmationApptDialog({ open, onSingleAppt, onConfirm, modal, auth, update, onClose }) {
  // const dates = update.map(item => item.date).join(", ");
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" className="mt-20" style={{
          fontSize: '22px', fontWeight: "800", color: "#3d3d3d", paddingRight: "10px", paddingLeft: "10px",
          paddingTop: "5px", paddingBottom: "5px", marginLeft: "12px"
        }}>
          Confirmation
        </Typography>
        <IconButton style={{ color: "red", marginLeft: "10px" }} onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </div>
      <Divider className="mt-10" />
      <DialogContent style={{ paddingTop: "5px", paddingBottom: "5px" }}>
        {modal ? (
          // <span>
          //   This is the series of appointments. Do you want to update the single appointment on{" "}
          //   <strong style={{ fontWeight: 'bold' }}>{auth.date}</strong>, or the series of appointments on{" "}
          //   <strong style={{ fontWeight: 'bold' }}>{dates}</strong>?
          // </span>
          <span>
            Do you want to update this appointment {" "}<strong style={{ fontWeight: 'bold' }}>{auth.date}</strong>?
          </span>
        ) : ''}
      </DialogContent>
      <DialogActions className="mt-20 mb-15">
        <Button variant="contained" onClick={onSingleAppt} className="ph-35 mr-5" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Update Appointment
        </Button>
        {/* <Button variant="contained" onClick={onConfirm} className="ph-35 mr-10" style={{ backgroundColor: "gray", color: "white", borderRadius: "10px" }}>
          Series Of Appointment
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export function DeleteApptDialog({ open, onSingleAppt, onConfirm, auth, update, onClose }) {
  const dates = update.map(item => item.date).join(", ");
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" className="mt-20" style={{
          fontSize: '22px', fontWeight: "800", color: "red", paddingRight: "10px", paddingLeft: "10px",
          paddingTop: "5px", paddingBottom: "5px", marginLeft: "12px"
        }}>
          Cancel Appointment
        </Typography>
        <IconButton style={{ color: "red", marginLeft: "10px" }} onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </div>
      <Divider className="mt-10" />
      <DialogContent style={{ paddingTop: "5px", paddingBottom: "5px" }}>
        {auth.type === 'Recurring Appointment' ? (
          <span>
            This is the series of appointments. Please confirm that you want to cancel the single appointment on{" "}
            <strong style={{ fontWeight: 'bold' }}>{auth.date}</strong>, or the series of appointments on{" "}
            <strong style={{ fontWeight: 'bold' }}>{dates}</strong>?
          </span>
        ) : 'Please confirm that you want to cancel this app'}
      </DialogContent>
      <DialogActions className="mt-20 mb-15">
        <Button variant="contained" onClick={onSingleAppt} className="ph-35 mr-5" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Confirm This Appointment
        </Button>
        {auth.type === 'Recurring Appointment' ?
          <Button variant="contained" onClick={onConfirm} className="ph-35 mr-10" style={{ backgroundColor: "gray", color: "white", borderRadius: "10px" }}>
            Cancel Series
          </Button> : ""}
      </DialogActions>
    </Dialog>
  );
};

export function CancelApptDailog({ open, onConfirm, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="body" className="mt-25" style={{ fontSize: '20px', fontWeight: "800", color: "red", padding: "10px", marginLeft: "12px" }}>
        Cancel Appoinment
        <Divider className="mt-15" />
      </Typography>
      <DialogContent className="mb-10">Are You Sure You Want To Cancel This Appoinment ?</DialogContent>
      <DialogActions className="mt-20 mb-15">
        <Button variant="contained" onClick={onConfirm} className="ph-35 mr-5" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Yes
        </Button>
        <Button variant="contained" onClick={onClose} className="ph-35 mr-10" style={{ backgroundColor: "gray", color: "white", borderRadius: "10px" }}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  )
}
