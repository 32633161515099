import React from "react";
import { Button, Container } from "@material-ui/core";
import { useNavigate } from "react-router";
import moment from 'moment';

function getTaskTitles(clientData) {
    const taskTitles = [];
    for (const shiftId in clientData.shiftData) {
        const shift = clientData.shiftData[shiftId];
        for (const taskId in shift) {
            const taskList = shift[taskId].tasksList;
            taskList.forEach((task) => taskTitles.push(task.title));
        }
    }
    return taskTitles.join(", ");
}

export function AppointmentModal({
    selectedAppointment,
    isMobile,
    isLandscape,
    clientDetails,
    employeeDetails,
    appoinments,
    auth,
    setIsModalOpen,
    setCancelAppt
}) {

    const navigate = useNavigate();

    const clientId = selectedAppointment?.clientId;
    const clientData = clientDetails?.find((client) => client.id === clientId);

    const createdBy = selectedAppointment.scheduledBy;
    const userData = employeeDetails?.data?.find(
        (emp) => emp.email === createdBy
    );

    const inputDate = new Date(selectedAppointment.date);
    const formattedDate = inputDate.toLocaleDateString("en-GB", {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
    });
    const month = inputDate.toLocaleString("en-US", { month: "short" });
    const day = inputDate.getDate();
    const getTiltles = getTaskTitles(clientData);

    const findMatchingAppointments = () => {
        if (!selectedAppointment) return [];

        return appoinments.filter((appointment) => {
            return (
                appointment.appointmentTypeID === selectedAppointment.appointmentTypeID &&
                appointment.type === selectedAppointment.type &&
                appointment.duration === selectedAppointment.duration &&
                appointment.numberOfTimes === selectedAppointment.numberOfTimes &&
                appointment.frequency === selectedAppointment.frequency &&
                appointment.clientId === selectedAppointment.clientId &&
                appointment.calendar === selectedAppointment.calendar &&
                appointment.scheduledBy === selectedAppointment.scheduledBy
            );
        });
    };

    const matchAppointmentsData = findMatchingAppointments();
    const matchingAppointments = matchAppointmentsData.sort((a, b) => new Date(a.date) - new Date(b.date));
    const currentDate = moment().startOf('day');
    const appointmentDate = moment(selectedAppointment?.date).startOf('day');
    const isDateValid = appointmentDate.isSameOrAfter(currentDate);

    return (
        <>
            <div>
                <Container style={{ position: "relative" }}>
                    <div className="stickyHeader">
                        <div className="d-flex flex-space-between flex-center">
                            <div className="flex-start">
                                <Button className="modal-close link"
                                    onClick={() => {
                                        setIsModalOpen(false);
                                        auth.setUpdateAppointmentData({});
                                    }}>
                                    Close
                                </Button>
                            </div>
                            <div className="d-flex">
                                <div className="mr-20">
                                    <Button
                                        variant="contained"
                                        style={{ backgroundColor: '#f62d51', color: "white", boxShadow: 'none', paddingLeft: "20px", paddingRight: "20px" }}
                                        onClick={() => {
                                            setCancelAppt(true);
                                            auth.setEditAppointmentData({ selectedAppointment });
                                            auth.setUpdateAppointmentData({ matchingAppointments });
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        variant="contained"
                                        style={{
                                            backgroundColor: isDateValid ? "green" : "gray",
                                            color: "white",
                                            boxShadow: 'none',
                                        }}
                                        onClick={() => {
                                            setIsModalOpen(false);
                                            navigate(`/appoinmentSchedule/${selectedAppointment?.id}`);
                                            auth.setUpdateAppointmentData({ matchingAppointments });
                                        }}
                                        disabled={!isDateValid} 
                                    >
                                        ReSchedule
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                width: "100%",
                                height: "1px",
                                backgroundColor: "#ddd",
                            }}
                            className="mb-20 mt-10"
                        ></div>
                    </div>

                    <div>
                        <div className="details-grid">
                            <div className="details-column">
                                <div className="appointment-header">
                                    <div class="date-icon">
                                        <div class="date-icon-top-lines">
                                            <div class="line">
                                                <div class="inner-line"></div>
                                            </div>
                                            <div class="line">
                                                <div class="inner-line"></div>
                                            </div>
                                            <div class="line">
                                                <div class="inner-line"></div>
                                            </div>
                                        </div>
                                        <div class="date-icon-day">{day}</div>
                                        <div class="date-icon-month">{month}</div>
                                    </div>

                                    <div>
                                        <h2 className="appointment-title">
                                            {clientData?.clientName}
                                        </h2>
                                        <div className="appointment-datetime">
                                            {formattedDate}
                                            <br />
                                            <span style={{ color: "#484848", fontWeight: "600" }}>
                                                {selectedAppointment?.time} -{" "}
                                                {selectedAppointment?.endTime}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`${isMobile || isLandscape
                                        ? "d-flex flex-direction-column"
                                        : "d-flex"
                                        }`}
                                >
                                    <div className="detail-item">
                                        <div className="detail-label">Phone</div>
                                        <div className="detail-value">
                                            {clientData?.contact[0]?.mobileNumber}
                                        </div>
                                    </div>
                                    <div
                                        className={`${isMobile || isLandscape
                                            ? "detail-item"
                                            : "detail-item ml-25"
                                            }`}
                                    >
                                        <div className="detail-label">Email</div>
                                        <div className="detail-value">
                                            {clientData?.contact[0]?.email}
                                        </div>
                                    </div>
                                    <div
                                        className={`${isMobile || isLandscape
                                            ? "detail-item"
                                            : "detail-item ml-25"
                                            }`}
                                    >
                                        <div className="detail-label">Client's Time Zone</div>
                                        <div className="detail-value">{clientData?.timeZone}</div>
                                    </div>
                                    <div
                                        className={`${isMobile || isLandscape
                                            ? "detail-item"
                                            : "detail-item ml-25"
                                            }`}
                                    >
                                        <div className="detail-label">Client Location</div>
                                        <div className="detail-value">
                                            {selectedAppointment?.forms?.[0]?.values?.filter((item) => item.name === "State" || item.name === "City")
                                                ?.map((item) => item.value || "N/A")?.join(", ")}
                                        </div>
                                    </div>
                                </div>
                                <div className="detail-item">
                                    <div className="detail-label">Client Address</div>
                                    <div className="detail-value">
                                        {selectedAppointment?.forms?.[0]?.values?.filter((item) => item.name !== "Shift Name")
                                            ?.map((item) => item.value || "N/A")?.join(", ")}
                                    </div>
                                </div>
                                <div className="detail-item">
                                    <div className="detail-label">Shift Details</div>
                                    <div className="detail-value">{getTiltles}</div>
                                </div>
                            </div>

                            <div className="details-column right">
                                <div className="d-flex flex-space-between">
                                    <div className="detail-item">
                                        <div className="detail-label2">Appointment Type</div>
                                        <div className="detail-value2">
                                            {selectedAppointment?.type}
                                        </div>
                                    </div>
                                    <div className="detail-item ml-10" style={{ width: "50%" }}>
                                        <div className="detail-label2">Employee Name</div>
                                        <div className="detail-value2">
                                            {selectedAppointment?.calendar}
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-space-between">
                                    <div className="detail-item">
                                        <div className="detail-label2">Shift Name</div>
                                        <div className="detail-value2">
                                            {selectedAppointment?.shiftName}
                                        </div>
                                    </div>
                                    <div className="detail-item ml-10" style={{ width: "50%" }}>
                                        <div className="detail-label2">Created By</div>
                                        <div className="detail-value2">
                                            {userData?.firstName}
                                            {userData?.lastName}
                                        </div>
                                    </div>
                                </div>
                                <div className="detail-item">
                                    <div className="detail-label2">Appointments</div>
                                    <div className="appointments-list">
                                        {matchingAppointments.map((apt, index) => (
                                            <div key={index} className="appointment-item">
                                                <div className="ml-10">
                                                    <span style={{ color: "#7d7d7d", fontWeight: "500" }}>
                                                        {apt?.time} on{" "}
                                                    </span>{" "}
                                                    <span
                                                        style={{
                                                            color: "#3c3c3c",
                                                            fontSize: "14px",
                                                            fontWeight: "800",
                                                        }}
                                                    >
                                                        {apt?.date}
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
};

export function AppointmentsDetailComponent({
    appointments,
    handleAppointmentOpen,
    appointmentRefs
}) {
    const sortedDates = Object.keys(appointments).sort((a, b) => new Date(a) - new Date(b));

    return (
        <div>
            {sortedDates.map((date) => (
                <div key={date} ref={(el) => (appointmentRefs.current[date] = el)} className="mb-30">
                    <div
                        className="d-flex flex-center pl-10"
                        style={{
                            backgroundColor: "#eef5f9",
                            height: "35px",
                            fontSize: "14px",
                            fontWeight: "500",
                        }}
                    >
                        {date}
                    </div>

                    {appointments[date].map((appointment, index) => (
                        <>
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    marginBottom: "10px",
                                    marginTop: "15px",
                                    position: "relative",
                                    marginLeft: "20px",
                                    marginRight: "5px",
                                    paddingLeft: "12px",
                                }}
                            >
                                <div
                                    style={{
                                        position: "absolute",
                                        left: "0",
                                        top: "0",
                                        bottom: "0",
                                        width: "4px",
                                        backgroundColor: "#eef5f9",
                                    }}
                                ></div>

                                <div style={{ flex: 1 }}>
                                    <div
                                        className="mv-5"
                                        style={{
                                            color: "#078037",
                                            fontSize: "14px",
                                            fontWeight: "600",
                                        }}
                                        onClick={() => handleAppointmentOpen(appointment)}
                                    >
                                        {appointment.calendar}
                                    </div>
                                    <div>{appointment.calendarTimezone}</div>
                                    <div
                                        className="mt-20"
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div className="mv-5">
                                            {appointment?.forms?.[0]?.values?.filter((item) => item.name === "Shift Name")
                                                ?.map((item) => item.value)} Shift
                                        </div>
                                        <div>{appointment.duration} minutes</div>
                                    </div>
                                    <div className="mb-10">
                                        {appointment.time} - {appointment.endTime}
                                    </div>
                                </div>
                            </div>
                            {index < appointments[date].length - 1 && (
                                <div
                                    style={{
                                        width: "100%",
                                        height: "1px",
                                        backgroundColor: "#ddd",
                                    }}
                                    className="mv-20"
                                ></div>
                            )}
                        </>
                    ))}
                </div>
            ))}
        </div>
    );
};
