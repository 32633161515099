import React, { useState } from "react";
import { Button, TextField, Typography } from "@material-ui/core";
import ConfirmCancel from "../../../components/DialogComponent/ConfirmCancel";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../../../components/AccordionComponent/AccordionComponent";
import DialogComponent from "../../../components/DialogComponent/DialogComponent";
import SingleSelect from "../../../components/SelectField/SingleSelect";
import validator from "validator";
import { useEffect } from "react";
import Avatar from "react-avatar-edit";
import TimeZoneData from "../../../assets/timezones.json";
import AddressByMap from './AddressByMap';
const stepId = 1;

function Step1(props) {
  const [stepContent, setStepContent] = useState({});
  const [stepError, setStepError] = useState({});

  const {
    currentStep,
    goToStep,
    onCancelAll,

    loading,
    classes,
  } = props;

  const allIndustryTypes = [
    "Retail",
    "Medical",
    "Industrial",
    "Restaurant",
    "Office",
    "Other",
  ].map((IndustyItem) => {
    return {
      label: `${IndustyItem}`,
      value: IndustyItem,
    };
  });
  const allFrequency = ["Daily", "Monthly", "Weekly"].map((frequencyItem) => {
    return {
      label: `${frequencyItem}`,
      value: frequencyItem,
    };
  });

  const selectedValues = [
    { label: "EDT - Eastern", value: "America/New_York" },
    { label: "CDT - Central", value: "America/Chicago" },
    { label: "MDT - Mountain", value: "America/Denver" },
    { label: "PDT - Pacific", value: "America/Los_Angeles" }
  ];

  const setEditData = (editData) => {
    setStepContent({
      clientName: editData?.clientName ? editData?.clientName : '',
      firstName: editData?.firstName ? editData?.firstName : '',
      lastName: editData?.clientName ? editData?.lastName : '',
      clientLogo: editData?.clientLogo ? editData?.clientLogo : '',
      industryType: editData?.industryType ? editData?.industryType : '',
      industryTypeOther: editData?.industryTypeOther ? editData?.industryTypeOther : '',
      summaryReportFrequency: editData?.summaryReportFrequency ? editData?.summaryReportFrequency : '',
      timeZone: editData?.timeZone ? editData?.timeZone : '',
      clientAddress: editData?.clientAddress ? editData?.clientAddress : '',
      clientLocation: editData?.clientLocation ? editData?.clientLocation : '',
      addressInfo: editData?.addressInfo ? editData?.addressInfo : {city: '', state:'', country: '', zip: '', address:''},
    })
  }
  useEffect(() => {
    if (props?.editData !== false) {
      setEditData(props?.editData);
    }
  }, [props?.editData])

  const handleInputChange = (event) => {
    const {
      target: { name, value },
    } = event;
    setStepContent({ ...stepContent, [name]: value });
    setStepError({ ...stepError, [name]: "" });
  };

  const validateAndGoNext = () => {
    const {
      clientName,
      industryType,
      industryTypeOther,
      summaryReportFrequency,
      firstName,
      lastName,
      timeZone,
      clientAddress,
      clientLocation
    } = stepContent;

    let errormessages = stepError;
    let isValid = true;
    if (
      typeof clientName === "undefined" ||
      clientName === undefined ||
      clientName === ""
    ) {
      isValid = false;
      errormessages = {
        ...errormessages,
        clientName: "Client Name is Required",
      };
    }
    else if (
      typeof firstName === "undefined" ||
      firstName === undefined ||
      firstName === ""
    ) {
      isValid = false;
      errormessages = {
        ...errormessages,
        firstName: "First Name is Required",
      };
    }
    else if (
      typeof lastName === "undefined" ||
      lastName === undefined ||
      lastName === ""
    ) {
      isValid = false;
      errormessages = {
        ...errormessages,
        lastName: "Last Name is Required",
      };
    } else if (!validator.isLength(clientName, { min: 0, max: 100 })) {
      isValid = false;
      errormessages = {
        ...errormessages,
        clientName: "Length of Client Name should not grater than 15 letters",
      };
    } else {
      errormessages = { ...errormessages, firstName: "" };
    }
    if (typeof industryType === "undefined" || industryType === "") {
      isValid = false;
      errormessages = {
        ...errormessages,
        industryType: "Industry Type is Required",
      };
    } else if (
      industryType === "Other" &&
      (typeof industryTypeOther === "undefined" || industryTypeOther === "")
    ) {
      isValid = false;
      errormessages = {
        ...errormessages,
        industryTypeOther: "Industry Type Name is Required",
      };
    } else {
      errormessages = {
        ...errormessages,
        industryType: "",
        industryTypeOther: "",
      };
    }
    if (
      typeof summaryReportFrequency == "undefined" ||
      summaryReportFrequency === ""
    ) {
      isValid = false;
      errormessages = {
        ...errormessages,
        summaryReportFrequency: "Summary Report Frequency is Required",
      };
    } else {
      errormessages = { ...errormessages, summaryReportFrequency: "" };
    }
    if (
      typeof timeZone == "undefined" ||
      timeZone === ""
    ) {
      isValid = false;
      errormessages = {
        ...errormessages,
        timeZone: "Time Zone is Required",
      };
    } else {
      errormessages = { ...errormessages, timeZone: "" };
    }
    // if (
    //   typeof clientAddress == "undefined" ||
    //   clientAddress === ""
    // ) {
    //   isValid = false;
    //   errormessages = {
    //     ...errormessages,
    //     clientAddress: "client Address is Required",
    //   };
    // } else {
    //   errormessages = { ...errormessages, clientAddress: "" };
    // }
    // if (
    //   typeof clientLocation == "undefined" ||
    //   clientLocation === ""
    // ) {
    //   isValid = false;
    //   errormessages = {
    //     ...errormessages,
    //     clientLocation: "Client Location is Required",
    //   };
    // } else {
    //   errormessages = { ...errormessages, clientLocation: "" };
    // }
    setStepError(errormessages);
    if (isValid)
      props?.validateAndGoNext && props?.validateAndGoNext(stepContent);
  };

  const onClose = () => {
    // setPview({ preview: null });
  }

  const onCrop = (preview) => {
    handleInputChange({ target: { name: "clientLogo", value: preview } });
  }
  const handleManualAddressChange = (field, value) => {
    setStepContent(prevContent => ({
      ...prevContent,
      addressInfo: {
        ...prevContent.addressInfo,
        [field]: value
      }
    }));
  };
  const onChangeAddress = (addressDetails) => {
    setStepContent({ ...stepContent, addressInfo: addressDetails });
  }
  return (
    <>
      <Accordion
        square
        disabled={currentStep !== 1 && stepId > currentStep}
        expanded={currentStep === stepId}
        onChange={() => goToStep(stepId)}
        className={`step${stepId}`}
      >
        <AccordionSummary
          aria-controls={`panel${stepId}d-content`}
          id={`panel${stepId}d-header`}
          className={
            currentStep === stepId || currentStep > stepId ? classes.complated : ""
          }
        >
          <Typography className={classes.heading}>
            Create Client Profile
          </Typography>
          <Typography className={classes.secondaryHeading}>Step {stepId}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={`stepContent w-50`}>
            {/* Content Starts */}

            <div className="stepSectionContent">
              <div className="formFieldwrap">
                <TextField
                  fullWidth={true}
                  size="small"
                  name="clientName"
                  id="clientName"
                  label="Client Name *"
                  variant="outlined"
                  placeholder="Client Name"
                  onChange={handleInputChange}
                  value={stepContent?.clientName ? stepContent?.clientName : ""}
                />
                {typeof stepError?.clientName !== "undefined" &&
                  stepError?.clientName !== "" && (
                    <span className="small error color-danger">
                      {stepError?.clientName}
                    </span>
                  )}
              </div>

              <div className="formFieldwrap">
                <TextField
                  fullWidth={true}
                  size="small"
                  name="firstName"
                  id="firstName"
                  label="First Name *"
                  variant="outlined"
                  placeholder="First Name"
                  onChange={handleInputChange}
                  value={stepContent?.firstName ? stepContent?.firstName : ""}
                />
                {typeof stepError?.firstName !== "undefined" &&
                  stepError?.firstName !== "" && (
                    <span className="small error color-danger">
                      {stepError?.firstName}
                    </span>
                  )}
              </div>
              <div className="formFieldwrap">
                <TextField
                  fullWidth={true}
                  size="small"
                  name="lastName"
                  id="lastName"
                  label="Last Name *"
                  variant="outlined"
                  placeholder="Last Name"
                  onChange={handleInputChange}
                  value={stepContent?.lastName ? stepContent?.lastName : ""}
                />
                {typeof stepError?.lastName !== "undefined" &&
                  stepError?.lastName !== "" && (
                    <span className="small error color-danger">
                      {stepError?.lastName}
                    </span>
                  )}
              </div>
              <div className="formFieldwrap">

                <div className="editor">
                  <Avatar
                    width={"100%"}
                    label="Choose a image for Logo"
                    height={295}
                    onCrop={onCrop}
                    onClose={onClose}
                    src={''}

                  />
                </div>
                <img
                  className="logoImgPreview"
                  src={stepContent?.clientLogo}
                  alt=""
                />

                <p>
                  <small>
                    Logo should not more than 320px width and should only in
                    JPG/JPEG format
                  </small>
                </p>
                {typeof stepError?.clientLogo !== "undefined" &&
                  stepError?.clientLogo !== "" && (
                    <span className="small error color-danger">
                      {stepError?.clientLogo}
                    </span>
                  )}
              </div>
              <div className="formFieldwrap">
                <SingleSelect
                  label="Industry Type *"
                  value={
                    stepContent?.industryType ? stepContent?.industryType : ""
                  }
                  className={`field`}
                  name={"industryType"}
                  onChange={handleInputChange}
                  options={[...allIndustryTypes]}
                />
                {typeof stepError?.industryType !== "undefined" &&
                  stepError?.industryType !== "" && (
                    <span className="small error color-danger">
                      {stepError?.industryType}
                    </span>
                  )}
              </div>
              {stepContent?.industryType &&
                stepContent?.industryType === "Other" ? (
                <div className="formFieldwrap">
                  <TextField
                    fullWidth={true}
                    size="small"
                    name="industryTypeOther"
                    id="industryTypeOther"
                    label="Industry Type Name *"
                    variant="outlined"
                    placeholder="Industry Type Name"
                    onChange={handleInputChange}
                    value={
                      stepContent?.industryTypeOther
                        ? stepContent?.industryTypeOther
                        : ""
                    }
                  />
                  {typeof stepError?.industryTypeOther !== "undefined" &&
                    stepError?.industryTypeOther !== "" && (
                      <span className="small error color-danger">
                        {stepError?.industryTypeOther}
                      </span>
                    )}
                </div>
              ) : (
                ""
              )}

              <div className="formFieldwrap">
                <SingleSelect
                  label="Summary Report Frequency"
                  value={
                    stepContent?.summaryReportFrequency
                      ? stepContent?.summaryReportFrequency
                      : ""
                  }
                  className={`field`}
                  name={"summaryReportFrequency"}
                  onChange={handleInputChange}
                  options={[...allFrequency]}
                />
                {typeof stepError?.summaryReportFrequency !== "undefined" &&
                  stepError?.summaryReportFrequency !== "" && (
                    <span className="small error color-danger">
                      {stepError?.summaryReportFrequency}
                    </span>
                  )}
              </div>
              <div className="formFieldwrap">
                <SingleSelect
                  fullWidth={true}
                  size="small"
                  name="timeZone"
                  id="timeZone"
                  label="TimeZone"
                  placeholder="TimeZone"
                  value={stepContent?.timeZone}
                  onChange={handleInputChange}
                  options={selectedValues}
                />
                {typeof stepError?.timeZone !== "undefined" &&
                  stepError?.timeZone !== "" && (
                    <span className="small error color-danger">
                      {stepError?.timeZone}
                    </span>
                  )}
              </div>
              <div className="formFieldwrap">
                <TextField
                  fullWidth={true}
                  size="small"
                  label="Address"
                  variant="outlined"
                  placeholder="Address"
                  // disabled
                  value={stepContent?.addressInfo?.address || ""}
                  onChange={(e) => handleManualAddressChange('address', e.target.value)}
                />
                </div>
                <div className="formFieldwrap">
                <TextField
                  fullWidth={true}
                  size="small"
                  label="City"
                  variant="outlined"
                  placeholder="City"
                  // disabled
                  value={stepContent?.addressInfo?.city || ""}
                  onChange={(e) => handleManualAddressChange('city', e.target.value)}
                />
                </div>
                <div className="formFieldwrap">
                <TextField
                  fullWidth={true}
                  size="small"
                  label="State"
                  variant="outlined"
                  placeholder="State"
                  // disabled
                  value={stepContent?.addressInfo?.state || ""}
                  onChange={(e) => handleManualAddressChange('state', e.target.value)}
                />
               
                </div>
                <div className="formFieldwrap">
               
                <TextField
                  fullWidth={true}
                  size="small"
                  label="Zip"
                  variant="outlined"
                  placeholder="Zip"
                  // disabled
                  value={stepContent?.addressInfo?.zip || ""}
                  onChange={(e) => handleManualAddressChange('zip', e.target.value)}
                />
                </div>
              <div className="formFieldwrap">
                <div style={{width:"100%", height:200}}>
                  <AddressByMap onChangeAddress={onChangeAddress} addressInfo={ stepContent?.addressInfo} />
                </div>
              </div>
              {/* <div className="formFieldwrap">
                <TextField
                  fullWidth={true}
                  size="small"
                  name="clientAddress"
                  id="clientAddress"
                  label="Client Address"
                  variant="outlined"
                  placeholder="Client Address"
                  onChange={handleInputChange}
                  value={stepContent?.clientAddress ? stepContent?.clientAddress : ""}
                />
                {typeof stepError?.clientAddress !== "undefined" &&
                  stepError?.clientAddress !== "" && (
                    <span clientAddress="small error color-danger">
                      {stepError?.clientAddress}
                    </span>
                  )}
              </div>
              <div className="formFieldwrap">
                <TextField
                  fullWidth={true}
                  size="small"
                  name="clientLocation"
                  id="clientLocation"
                  label="Client Location"
                  variant="outlined"
                  placeholder="Client Location"
                  onChange={handleInputChange}
                  value={stepContent?.clientLocation ? stepContent?.clientLocation : ""}
                />
                {typeof stepError?.clientLocation !== "undefined" &&
                  stepError?.clientLocation !== "" && (
                    <span className="small error color-danger">
                      {stepError?.clientLocation}
                    </span>
                  )}
              </div> */}
            </div>
            {/* Content Ends */}
            <div className="buttonsWrap">
              <Button
                type="button"
                color={"primary"}
                variant={"contained"}
                className="flatbutton themeButton mr-15"
                onClick={() => validateAndGoNext()}
                disabled={loading === true ? true : false}
              >
                {loading === true ? "Loading..." : "Next"}
              </Button>
              <DialogComponent
                buttonWrapClassName={"d-inline"}
                buttonClassName={"flatbutton"}
                buttonProps={{
                  size: "medium",
                  variant: "contained",
                  disabled: loading === true ? true : false,
                }}
                buttonLabel={"Cancel"}
                ModalTitle={"Are you sure you want to cancel this entry?"}
                ModalContent={<ConfirmCancel onCancelAll={onCancelAll} />}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
export default Step1;
