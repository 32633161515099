import React, { useEffect, useState } from "react";
import { format } from 'date-fns';
import moment from 'moment';
import SingleSelect from "../../../../components/SelectField/SingleSelect";
import { useMediaQuery } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button } from "@material-ui/core";

const NavBar = (props) => {
    const [mainCalDate, setMainCalDate] = useState(new Date());
    const [previousView, setPreviousView] = useState(null);
    const today = new Date()
    const isMobile = useMediaQuery("(max-width:767px)");
    const getFirstWeekOfMonth = (date) => {
        const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        const dayOfWeek = firstDayOfMonth.getDay();
        const startOfWeek = new Date(firstDayOfMonth);
        startOfWeek.setDate(firstDayOfMonth.getDate() - dayOfWeek);
        return startOfWeek;
    };
    const [currentWeek, setCurrentWeek] = useState(getFirstWeekOfMonth(today));
    const [selecteHandle, setSelecteHandle] = useState(null);

    useEffect(() => {
        const startOfWeek = new Date(currentWeek);
        startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
        const datesArray = [];
        for (let i = 0; i < 7; i++) {
            const nextDate = new Date(startOfWeek);
            nextDate.setDate(startOfWeek.getDate() + i);
            datesArray.push(nextDate);
        }
        props?.setWeekDates(datesArray);
        if (props?.view === 'week') {
            filterWeeklyData(datesArray);
        }
        if (previousView === "month" && props?.view === "day") {
            props?.setSelectedDayDate(props?.currentDate)
          }
          props?.view === "month" && props?.setSelectedDayDate(currentWeek);
          
        const isDayToWeek = previousView === "day" && props?.view === "week";
        const isWeekToDay = previousView === "week" && props?.view === "day";
        const isWeekNavigation = selecteHandle === "weekPrevious" || selecteHandle === "weekNext";
      
        if ((isDayToWeek || isWeekToDay) && isWeekNavigation) {
            props?.setSelectedDayDate(datesArray[0]);
        } else {
            props?.setSelectedDayDate(props?.currentDate);
        }
    }, [currentWeek, mainCalDate, props?.view, previousView, selecteHandle])


    const filterWeeklyData = (weekDates) => {
        let filteredAppointments = {};
        if (props?.upcomingAppointments && Array.isArray(weekDates)) {
            const weekDatesSet = new Set(
                weekDates.map((date) => new Date(date).toISOString().split("T")[0])
            );
            Object.keys(props?.upcomingAppointments).forEach((date) => {
                const appointmentDate = new Date(date).toISOString().split("T")[0];
                if (weekDatesSet.has(appointmentDate)) {
                    filteredAppointments[date] = props?.upcomingAppointments[date];
                }
            });
        }
        props?.setFilteredAppointments(filteredAppointments);
    }

    const formatDate = (date) => {
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long'
        }).format(date);
    };

    const previousMonth = () => {
        if (props?.view === "week") {
            setSelecteHandle("weekPrevious");
            setCurrentWeek((prevDate) => {
                const newDate = new Date(prevDate);
                newDate.setDate(newDate.getDate() - 7);
                return newDate;
            });
            setMainCalDate((prevDate) => {
                const newDate = new Date(prevDate);
                newDate.setDate(newDate.getDate() - 7);
                return newDate;
            });
            props?.setCurrentDate((prevDate) => {
                const newDate = new Date(prevDate);
                newDate.setDate(newDate.getDate() - 7);
                return newDate;
            })
        } else if (props?.view === "day") {
            setSelecteHandle("dayPrevious");
            const newSelectedDate = new Date(props?.selectedDayDate);
            newSelectedDate.setDate(newSelectedDate.getDate() - 1);

            props?.setSelectedDayDate(newSelectedDate);
            setCurrentWeek(newSelectedDate);
            props?.setCurrentDate(newSelectedDate);
            setMainCalDate((prevDate) => {
                const newDate = new Date(prevDate);
                newDate.setDate(newDate.getDate() + 1);
                return newDate;
            });
            let filteredAppointments = {};
            if (props?.upcomingAppointments) {
                const selectedDate = moment(newSelectedDate).format("YYYY-MM-DD");
                Object.keys(props?.upcomingAppointments).forEach((date) => {
                    const appointmentDate = moment(date).format("YYYY-MM-DD");
                    if (appointmentDate === selectedDate) {
                        filteredAppointments[date] = props?.upcomingAppointments[date];
                    }
                });

                props?.setFilteredAppointments(filteredAppointments);
            }
        } else {
            setSelecteHandle("monthPrevious");
            props?.setCurrentDate(new Date(props?.currentDate.getFullYear(), props?.currentDate.getMonth() - 1));
            setCurrentWeek(new Date(props?.currentDate.getFullYear(), props?.currentDate.getMonth() - 1));
            setMainCalDate(new Date(props?.currentDate.getFullYear(), props?.currentDate.getMonth() - 1))
            let filteredAppointments = {};

            const viewMonth = formatDate(new Date(props?.currentDate.getFullYear(), props?.currentDate.getMonth() - 1));
            const filtered = Object.keys(props?.upcomingAppointments).filter((date) => {
                const appointmentDate = new Date(date);

                const appointmentMonthYear = format(appointmentDate, 'MMMM yyyy');

                return appointmentMonthYear === viewMonth;
            });

            filteredAppointments = filtered.reduce((result, date) => {
                result[date] = props?.upcomingAppointments[date];
                return result;
            }, {});

            props?.setFilteredAppointments(filteredAppointments);
        }
    };

    const nextMonth = () => {
        if (props?.view === "week") {
            setSelecteHandle("weekNext");
            setCurrentWeek((prevDate) => {
                const newDate = new Date(prevDate);
                newDate.setDate(newDate.getDate() + 7);
                return newDate;
            });
            setMainCalDate((prevDate) => {
                const newDate = new Date(prevDate);
                newDate.setDate(newDate.getDate() + 7);
                return newDate;
            });
            props?.setCurrentDate((prevDate) => {
                const newDate = new Date(prevDate);
                newDate.setDate(newDate.getDate() + 7);
                return newDate;
            })
        } else if (props?.view === "day") {
            setSelecteHandle("dayNext");
            const newSelectedDate = new Date(props?.selectedDayDate);
            newSelectedDate.setDate(newSelectedDate.getDate() + 1);

            props?.setSelectedDayDate(newSelectedDate);
            setCurrentWeek(newSelectedDate);
            props?.setCurrentDate(newSelectedDate);
            setMainCalDate((prevDate) => {
                const newDate = new Date(prevDate);
                newDate.setDate(newDate.getDate() + 1);
                return newDate;
            });
            let filteredAppointments = {};
            if (props?.upcomingAppointments) {
                const selectedDate = moment(newSelectedDate).format("YYYY-MM-DD");
                Object.keys(props?.upcomingAppointments).forEach((date) => {
                    const appointmentDate = moment(date).format("YYYY-MM-DD");
                    if (appointmentDate === selectedDate) {
                        filteredAppointments[date] = props?.upcomingAppointments[date];
                    }
                });

                props?.setFilteredAppointments(filteredAppointments);
            }
        } else {
            setSelecteHandle("monthNext");
            props?.setCurrentDate(new Date(props?.currentDate.getFullYear(), props?.currentDate.getMonth() + 1));
            setCurrentWeek(new Date(props?.currentDate.getFullYear(), props?.currentDate.getMonth() + 1));
            setMainCalDate(new Date(props?.currentDate.getFullYear(), props?.currentDate.getMonth() + 1))
            let filteredAppointments = {};

            const viewMonth = formatDate(new Date(props?.currentDate.getFullYear(), props?.currentDate.getMonth() + 1));
            const filtered = Object.keys(props?.upcomingAppointments).filter((date) => {
                const appointmentDate = new Date(date);

                const appointmentMonthYear = format(appointmentDate, 'MMMM yyyy');

                return appointmentMonthYear === viewMonth;
            });

            filteredAppointments = filtered.reduce((result, date) => {
                result[date] = props?.upcomingAppointments[date];
                return result;
            }, {});

            props?.setFilteredAppointments(filteredAppointments);
        }
    };

    const getWeekRange = (dates) => {
        const startOfWeek = new Date(dates[0]);
        const endOfWeek = new Date(dates[dates.length - 1]);

        return `${moment(startOfWeek).format('Do MMM')} - ${moment(endOfWeek).format('Do MMM')}`;
    };

    const handelViewChange = (view) => {
        props?.setView(view);
        setPreviousView(props?.view);
        let filteredAppointments = {};
        if (view === 'week') {
            if (props?.upcomingAppointments && Array.isArray(props?.weekDates)) {
                const weekDatesSet = new Set(props?.weekDates?.map((date) => moment(date).format("YYYY-MM-DD")));                
                Object.keys(props?.upcomingAppointments).forEach((date) => {
                    const appointmentDate = moment(date).format("YYYY-MM-DD");
                    if (weekDatesSet.has(appointmentDate)) {
                        filteredAppointments[date] = props?.upcomingAppointments[date];
                    }
                });
            }
            props?.setFilteredAppointments(filteredAppointments);
        }
        else if (view === 'month') {
            const viewMonth = formatDate(new Date(props?.currentDate.getFullYear(), props?.currentDate.getMonth()));
            const filtered = Object.keys(props?.upcomingAppointments).filter((date) => {
                const appointmentDate = new Date(date);

                const appointmentMonthYear = format(appointmentDate, 'MMMM yyyy');

                return appointmentMonthYear === viewMonth;
            });

            filteredAppointments = filtered.reduce((result, date) => {
                result[date] = props?.upcomingAppointments[date];
                return result;
            }, {});

            props?.setFilteredAppointments(filteredAppointments);
        }
        else if (view === 'day') {
            if (props?.upcomingAppointments) {
                const selectedDate = moment(props?.selectedDayDate).format("YYYY-MM-DD");
                Object.keys(props?.upcomingAppointments).forEach((date) => {
                    const appointmentDate = moment(date).format("YYYY-MM-DD");
                    if (appointmentDate === selectedDate) {
                        filteredAppointments[date] = props?.upcomingAppointments[date];
                    }
                });
                props?.setFilteredAppointments(filteredAppointments);
            }
        }

    }

    return (
        <div className="calendar-header">
            <div className="calendar-nav">
                <ArrowBackIosNewIcon className="arrowIcon" onClick={previousMonth} />
                {props?.view === "month" &&
                    <h2 className="calendar-title">{formatDate(props?.currentDate)}</h2>
                }
                {props?.view === "week" &&
                    <h2 className="calendar-title">{getWeekRange(props?.weekDates)}</h2>
                }
                {props?.view === "day" &&
                    <h2 className="calendar-title">{moment(props?.selectedDayDate).isSame(moment(), 'day') ? 'Today' : moment(props?.selectedDayDate).format("DD MMM")}</h2>
                }
                <ArrowForwardIosIcon className="arrowIcon" onClick={nextMonth} />
            </div>
            <div className='d-flex'>
                {!isMobile ?
                    <div className="mr-40" style={{ width: isMobile ? "250px" : "300px" }}>
                        <SingleSelect
                            value={props?.empNames}
                            name={"employeeName"}
                            label={"Employee Name"}
                            onChange={props?.handleEmpNameChange}
                            options={props?.employeeNameList}
                            datetime={true}
                        />
                    </div> : null}
                <div className="calendar-controls">
                    <div className="view-toggle">
                        <Button
                            onClick={() => handelViewChange('day')}
                            variant='outlined'
                            className={`navButtons view-toggle-btn ${props?.view === 'day' ? 'active' : ''}`}
                        >
                            Day
                        </Button>
                        <Button
                            onClick={() => handelViewChange('week')}
                            variant='outlined'
                            className={`navButtons view-toggle-btn ${props?.view === 'week' ? 'active' : ''}`}
                        >
                            Week
                        </Button>
                        <Button
                            onClick={() => handelViewChange('month')}
                            variant='outlined'
                            className={`navButtons view-toggle-btn ${props?.view === 'month' ? 'active' : ''}`}
                        >
                            Monthly
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavBar;