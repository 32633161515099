/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";
import CircularProgressWithLabel from "../../../components/CircularProgressWithLabel";
import SingleSelect from "../../../components/SelectField/SingleSelect";
import { acuitygetAppointments } from "../../../services/apiservices/acuity";
import { getFilterAppoinments } from "../../../services/apiservices/appoinments";
import { getTimesheetList } from "../../../services/apiservices/timesheets";
import styles from "../dashboard.module.scss";
import moment from "moment/moment";
const Appointments = (props) => {

  const [progress, setProgress] = useState();
  const [frequency, setFrequency] = useState(moment().format(moment.HTML5_FMT.DATE));
  const [totalappts, setTotalappts] = useState([]);
  const [completedappts, setCompletedAppts] = useState();

  const frequencyList = [
    { label: "Daily", value: `${moment().format(moment.HTML5_FMT.DATE)}` },
    { label: "Yesterday", value: moment().subtract(1, "days").format(moment.HTML5_FMT.DATE) },

  ];
  const onChangeInput = (e) => {
    setFrequency(e.target.value);
  };
  const updateProgress = (newval) => {
    setProgress(newval);
  };



  useEffect(() => {
    const getdata = async () => {
     
      const resp = await getFilterAppoinments(frequency, frequency);
      const appts = resp?.appointments;
      if(appts) {
        const totalAppts = appts?.length || 0;
        setTotalappts(totalAppts);

        const filteredAppointments = appts.filter((appt) =>  appts?.completed == true);
        const totalCompletedAppts = filteredAppointments?.length || 0;
        setCompletedAppts(totalCompletedAppts);

        const progress = (100/totalAppts)*totalCompletedAppts;
        updateProgress(progress);
      }
      
      
    };

    getdata();
  }, [frequency]);



  return (
    <>
      <Grid container spacing={2} className="mb-10">
        <Grid item xs={12} sm={7}>
          <p className="p-0 m-0 mb-5 fonthe">
            Appointments
          </p>
          <SingleSelect
            value={frequency}
            className={`field newonr`}
            name={"frequency"}
            onChange={(e) => onChangeInput(e)}
            options={frequencyList}
          />
        </Grid>

        <Grid item xs={12} sm={5} className="text-right">
          <CircularProgressWithLabel signt={"yes"} value={isNaN(progress) ? 0 : progress} wrapclass="color-appts" />
        </Grid>

      </Grid>

      <ul>
        <li className={styles.listItem}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              Total Appointments{" "}
            </Grid>
            <Grid item xs={12} sm={3} className="text-right">
              <strong>{totalappts}</strong>
            </Grid>
          </Grid>
        </li>
        <li className={styles.listItem}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              Pending
            </Grid>
            <Grid item xs={12} sm={3} className="text-right">
              <strong>{totalappts - completedappts}</strong>
            </Grid>
          </Grid>
        </li>
        <li className={styles.listItem}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              Completed
            </Grid>
            <Grid item xs={12} sm={3} className="text-right">
              <strong>{completedappts}</strong>
            </Grid>
          </Grid>
        </li>
      </ul>
    </>
  );
};
export default Appointments;
